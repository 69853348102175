import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { emailValidationSchema } from "../../constant/validationSchema";
import CustomButton from "../../components/ActionButtons/CustomButton";
import { CustomTextField } from "../../components/InputFields/TextFields";
import EditIcon from "@mui/icons-material/Edit";
import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />;
});

export function Forgot({
  onSubmit,
  name = "email",
  value,
  label = "Email",
  iconText = "",
  onClose,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
  };

  const handleCloseSuccess = () => {
    setOpenMd(false);
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const forgotMail = (data, resetForm) => {
    ApiClient.forgetPassword.post(data).then((res) => {
      if (res) {
        CustomToaster.success(res.message);
        handleCloseSuccess();
        resetForm();
        navigate('/forgot-password');
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      [name]: value,
    },
    validationSchema: Yup.object().shape({
      [name]: emailValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      forgotMail(values, resetForm);
    },
  });

  return (
    <>
      <Typography
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
      >
        <Button
          onClick={() => {
            if (window.innerWidth < 900) {
              handleClickOpen();
            } else {
              handleClickOpenMd();
            }
          }}
          size="small"
        >
          Forgot Password
        </Button>
      </Typography>

      <Dialog fullWidth open={openMd} onClose={handleCloseMd}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Forgot Password
          <IconButton
            aria-label="close"
            onClick={handleCloseMd}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              alignItems: "center",
              width: "100%",
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container padding={0} justifyContent="center">
              <Grid xs={12} item>
                <CustomTextField
                  form={formik}
                  field={formik.getFieldProps(name)}
                  margin="normal"
                  label={label}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={formik.handleSubmit}>Reset</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{
                background: "none",
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <Typography sx={{ ml: 0.5, flex: 1 }} variant="h6" component="div">
              Forgot Password
            </Typography>
          </Toolbar>
        </AppBar>

        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
            width: "100%",
          }}
          onSubmit={formik.handleSubmit}
        >
          <Grid container padding={2} justifyContent="center">
            <Grid xs={12} item>
              <CustomTextField
                form={formik}
                field={formik.getFieldProps(name)}
                margin="normal"
                label={label}
              />
            </Grid>

            <CustomButton disabled={formik?.isSubmitting}  label={"Reset"} />
          </Grid>
        </Box>
      </Dialog>



    </>
  );
}
