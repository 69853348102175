import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { phoneValidationSchema, stringValidationSchema } from "../../constant/validationSchema";
import CustomButton from "../ActionButtons/CustomButton";
import { CustomTextField } from "../InputFields/TextFields";
import EditIcon from "@mui/icons-material/Edit";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

export function UpdateText({ onSubmit, name, value, label = '', iconText = '', onClose, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
  };

  const handleCloseSuccess = () => {
    setOpenMd(false);
    setOpen(false);
    if (onClose) {
      onClose()
    }
  }

  const formik = useFormik({
    initialValues: {
      [name]: value,
    },
    validationSchema: Yup.object().shape({
      [name]: stringValidationSchema,
    }),
    onSubmit: (values) => {
      onSubmit(values, handleCloseSuccess, rest)
    },
  });



  return (
    <>
      {iconText ? (
        <Button fullWidth onClick={() => {
          if (window.innerWidth < 900) {
            handleClickOpen()
          } else {
            handleClickOpenMd()
          }
        }
        } >
          <Typography sx={{ fontSize: 16, fontWeight: '500', pr: 1 }}>{iconText}</Typography>
          <EditIcon />
        </Button>
      ) : (
        <IconButton onClick={() => {
          if (window.innerWidth < 900) {
            handleClickOpen()
          } else {
            handleClickOpenMd()
          }
        }
        } size="small" >
          <EditIcon fontSize="12px" />
        </IconButton>
      )}




      <Dialog fullWidth open={openMd} onClose={handleCloseMd}>
        <DialogTitle> Update {label} </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              alignItems: "center",
              width: '100%',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container padding={2} justifyContent="center">
              <Grid xs={12} item >
                <CustomTextField
                  form={formik}
                  field={formik.getFieldProps(name)}
                  margin="normal"
                  label={label}
                />
              </Grid>
            </Grid>


          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMd}>Cancel</Button>
          <Button onClick={formik.handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 0.5, flex: 1 }} variant="h6" component="div">
              Update {label}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

          </Toolbar>
        </AppBar>

        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
            width: '100%',
          }}
          onSubmit={formik.handleSubmit}
        >
          <Grid container padding={2} justifyContent="center">
            <Grid xs={12} item >
              <CustomTextField
                form={formik}
                field={formik.getFieldProps(name)}
                margin="normal"
                label={label}
              />
            </Grid>

            <CustomButton disabled={formik?.isSubmitting}  label={"Save"} />
          </Grid>

        </Box>

      </Dialog>

    </>
  );
}
