import React, { useEffect, useState } from "react";
import {
  AddServiceActionButton,
  ClientSelectRadioCard,
  JobDetailContainerListCard,
  NavBar,
  SelectAddressRadioCard,
} from "../../../components";
import {
  Box,
  Button,
  Divider,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  arrayValidationSchema,
  numericValidationSchema,
  stringValidationSchema,
} from "../../../constant/validationSchema";
import { CustomTextField } from "../../../components/InputFields/TextFields";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { useNavigate, useSearchParams } from "react-router-dom";
import CalculateTotalServiceMaterial from "../../../utils/CalculateTotalServiceMaterial";
import { useSelector } from "react-redux";
import {
  transformMaterialArray,
  transformServiceArray,
} from "../../../utils/transformMaterialService";
import { ThemeColors } from "../../../constant/ThemeColors";

export function JobAdd() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("repost");
  const quoteId = searchParams.get("quote");
  const taxList = useSelector((state) => state?.constant?.taxList);

  const addTax = () => {
    let rate = taxList[0] ? taxList[0]?.rate : 0;
    let tax = (Number(subTotal) * Number(rate)) / 100;
    return {
      total: Number(subTotal) + (Number(subTotal) * Number(rate)) / 100,
      tax,
    };
  };

  const [services, setServices] = useState([]);
  const [material, setMaterial] = useState([]);

  const handleOnSubmitMaterial = (items) => {
    setMaterial([...material, ...Object.values(items)]);
  };

  const handleOnUpdateMaterial = (index, material) => {
    setMaterial((prevState) => {
      let newService = prevState;
      newService[index] = material;
      return [...newService];
    });
  };

  const [selectValue, setSelectValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");

  const formik = useFormik({
    initialValues: {
      materials: [],
      services: [],
      address_id: "",
      client_id: "",
      name: "",
      description: "",
      schedule: true,
    },
    validationSchema: Yup.object().shape({
      address_id: numericValidationSchema,
      client_id: numericValidationSchema,
      name: stringValidationSchema,
      description: stringValidationSchema,
      services: arrayValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      createJob(values, resetForm);
    },
  });

  const createJob = (data, resetForm) => {
    ApiClient.job
      .post({ ...data, tax_percent_rate: Number(taxList[0]?.rate || 0) })
      .then((res) => {
        if (res) {
          CustomToaster.success(res.message);
          resetForm();
          setMaterial([]);
          setSelectValue("");
          setServices([]);
          if (data?.schedule) {
            navigate("/jobs/update/" + res?.data?.job_id);
          } else if (jobId) {
            navigate("/jobs/detail/" + res?.data?.job_id);
          } else {
            navigate(-1);
          }
        }
      });
  };

  const [subTotal, setSubTotal] = useState(0);
  const calculateSubTotal = () => {
    setSubTotal(
      CalculateTotalServiceMaterial(
        formik.values?.materials,
        formik.values?.services
      )
    );
  };

  useEffect(() => {
    formik.setFieldValue("services", transformServiceArray(services));
    formik.setFieldValue("materials", transformMaterialArray(material));
    formik.setFieldValue("address_id", selectedAddress?.id);
    formik.setFieldValue("client_id", selectValue?.id);

    setSubTotal(
      CalculateTotalServiceMaterial(
        transformMaterialArray(material),
        transformServiceArray(services)
      )
    );
  }, [material, services, selectValue, selectedAddress]);

  // useEffect(() => {
  //   calculateSubTotal();
  // }, [formik]);

  const [clientId, setClientId] = useState("");
  const fetchJobDetail = () => {
    ApiClient.job.get("?job_id=" + jobId).then((res) => {
      if (res) {
        const jobDetail = res?.data[0];
        setClientId(jobDetail?.client_id);
        formik.setFieldValue("address_id", Number(jobDetail?.address_id));
        formik.setFieldValue("client_id", jobDetail?.client_id);
        formik.setFieldValue("team", jobDetail?.team_details);
        formik.setFieldValue("name", jobDetail?.name);
        formik.setFieldValue("description", jobDetail?.description);
        setMaterial(jobDetail?.materials);
        setServices(jobDetail?.services);
      }
    });
  };

  const [quoteDetail, setQuoteDetail] = useState("");

  const fetchQuoteDetail = () => {
    ApiClient.quote
      .get("?quote_id=" + quoteId)
      .then((res) => {
        if (res) {
          const jobDetail = res?.data[0];
          setClientId(jobDetail?.client_id);
          formik.setFieldValue("address_id", Number(jobDetail?.address_id));
          formik.setFieldValue("client_id", jobDetail?.client_id);
          formik.setFieldValue("team", jobDetail?.team_details);
          formik.setFieldValue("name", jobDetail?.name);
          formik.setFieldValue("description", "");
          setMaterial(jobDetail?.materials);
          setServices(jobDetail?.services);

          // setQuoteDetail(res?.data[0] || res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (jobId) {
      fetchJobDetail();
    }
  }, [jobId]);

  useEffect(() => {
    if (quoteId) {
      fetchQuoteDetail();
    }
  }, [quoteId]);

  return (
    <>
      <NavBar title="Add Job" goBack noFilter noSearch />
      <Box sx={{ p: 1 }}>
        <ClientSelectRadioCard clientId={clientId} onSubmit={setSelectValue} />
        {selectValue?.id ? (
          <>
            <SelectAddressRadioCard
              data={selectValue}
              onSubmit={setSelectedAddress}
              hideEdit
            />
            <Divider />

            <Box mt={4} mb={2}>
              <CustomTextField
                label="Job Name *"
                form={formik}
                field={formik.getFieldProps("name")}
              />
            </Box>
            <JobDetailContainerListCard
              cardTitle="Services *"
              editable
              listData={services}
              setListData={setServices}
              hideListButton
              cardButton="service"
            />

            <Typography
              variant="subtitle2"
              component={"span"}
              sx={{ color: "red" }}
            >
              {formik?.touched.services && formik?.errors?.services}
            </Typography>

            <JobDetailContainerListCard
              cardTitle="Material"
              editable
              listData={material}
              setListData={setMaterial}
              hideListButton
              cardButton="material"
            />

            <Box
              sx={{
                my: 3,
                border: "1px solid #B7D4FF",
                borderBottom: "3px solid #B7D4FF",
                padding: "15px",
                // backgroundColor: "#F8F9FC",
                marginTop: "30px",
                borderRadius: "7px",
              }}
            >
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                component="p"
              >
                Subtotal:{" "}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    // color: "#000",
                  }}
                >
                  ${subTotal?.toFixed(2)}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                component="p"
              >
                Tax: {taxList[0] ? `${taxList[0]?.rate}%` : ""}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    // color: "#000",
                  }}
                >
                  ${addTax()?.tax?.toFixed(2)}
                </Typography>
              </Typography>
              <Divider p={1} />
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                component="p"
              >
                Grand Total:{" "}
                <span
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    // color: "#000",
                  }}
                >
                  ${addTax()?.total?.toFixed(2)}
                </span>
              </Typography>
            </Box>

            <Box my={4} pb={10}>
              <CustomTextField
                multiline
                form={formik}
                label="Job Description: *"
                field={formik.getFieldProps("description")}
              />
            </Box>

            <Box
              sx={{
                position: "fixed",
                zIndex: 100,
                bottom: 20,
                left: 0,
                right: 0,
                maxWidth: "400px",
                width: "90%",
                display: "flex",
                py: 1,
                margin: "auto",
                fontSize: 18,
              }}
              gap={2}
            >
              <Button
                variant="outlined"
                sx={{ py: 1, fontWeight: "500" }}
                fullWidth
                onClick={() => {
                  formik.setFieldValue("schedule", false);
                  formik.handleSubmit();
                }}
              >
                Save
              </Button>
              <Button
              disabled={formik?.isSubmitting} 
                variant="contained"
                sx={{ py: 1, fontWeight: "500" }}
                fullWidth
                onClick={formik.handleSubmit}
              >
                Schedule
              </Button>
            </Box>

            {/* <CustomButton onClick={formik.handleSubmit} label="Save" /> */}
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
