import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { emailValidationSchema } from "../../constant/validationSchema";
import { CustomTextField } from "../InputFields/TextFields";
import CustomButton from "../ActionButtons/CustomButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

export function AddEmail({ id, onUpdate }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
  };


  const addEmail = (values) => {
    ApiClient.email.post(values, id).then(res => {
      if (res) {
        CustomToaster.success(res.message)
        handleClose();
        handleCloseMd();
        onUpdate();

      }
    }).catch(err => {
      CustomToaster.error(err?.message + "error")
    })

  }



  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: emailValidationSchema,
    }),
    onSubmit: (values) => {
      addEmail(values)
      console.log("add Email", values);
    },
  });





  return (
    <>
      <Typography

        onClick={() => {
          if (window.innerWidth < 900) {
            handleClickOpen()
          } else {
            handleClickOpenMd()
          }
        }
        }
        sx={{
          padding: 0,
          mt: -1,
          ":hover": {
            cursor: 'pointer'
          },
          color: (theme) => theme.palette.grey[600],
          fontSize: 14,
          fontWeight: "500",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <AddCircleIcon htmlColor="#5E95FF" sx={{ p: 0.3 }} />
        Add Another Email
      </Typography>



      <Dialog fullWidth open={openMd} onClose={handleCloseMd}>
        <DialogTitle> Add Email Address</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              alignItems: "center",
              width: '100%',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container padding={2} justifyContent="center">
              <Grid xs={12} item >
                <CustomTextField
                  form={formik}
                  field={formik.getFieldProps("email")}
                  margin="normal"
                  label="Email"
                />
              </Grid>
            </Grid>


          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMd}>Cancel</Button>
          <Button onClick={formik.handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>

            <Typography sx={{ ml: 0.5, flex: 1 }} variant="h6" component="div">
              Add Email Address
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

          </Toolbar>
        </AppBar>

        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
            width: '100%',
          }}
          onSubmit={formik.handleSubmit}
        >
          <Grid container padding={2} justifyContent="center">
            <Grid xs={12} item >
              <CustomTextField
                form={formik}
                field={formik.getFieldProps("email")}
                margin="normal"
                label="Email"
              />
            </Grid>

            <CustomButton disabled={formik?.isSubmitting} label={"Save"} />
          </Grid>

        </Box>

      </Dialog>

    </>
  );
}
