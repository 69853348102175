const transformMaterialArray = (inputArray) => {
    const outputArray = [];
  
    for (const material of inputArray) {
      const unitCount = parseInt(material?.unit_count || material?.qty || 0);
      const purchasePrice = parseInt(
        material?.unit_price || 0
      );
  
      const total = unitCount * purchasePrice;
  
      const transformedMaterial = {
        name: material.name,
        unit_count: unitCount.toString(),
        tax: "1",
        unit_price: purchasePrice.toString(),
        total: total.toString(),
      };
  
      outputArray.push(transformedMaterial);
    }
  
    return outputArray;
  };
  
  const transformServiceArray = (inputArray) => {
    const outputArray = [];
  
    for (const service of inputArray) {
      const unitCount = parseInt(service?.unit_count || service?.qty || 0);
      const price = parseInt(service?.unit_price || 0);
  
      const total = unitCount * price;
  
      const transformedservice = {
        name: service.name,
        unit_count: unitCount.toString(),
        tax: "1",
        unit_price: price.toString(),
        total: total.toString(),
      };
  
      outputArray.push(transformedservice);
    }
  
    return outputArray;
  };




  export {transformMaterialArray, transformServiceArray}