import {
  AppBar,
  Box,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { UpdateAddress } from "../../UpdateDetails/UpdateAddress";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

export function SelectAddressRadioCard({
  label = " Address",
  data = [],
  onSubmit = false,
  addressId = false,
  hideEdit = false,
}) {
  const [addresses, setAddresses] = React.useState(data?.additionaladdress);
  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
  };

  const [selectedAddress, setSelectedAddress] = React.useState("");

  const handleChange = (location) => {
    if (location) {
      setSelectedAddress(location);
      onSubmit(location);
    }
    handleCloseMd();
  };

  const fetchLatestAddress = () => {
    ApiClient.client.get(data.id).then((res) => {
      if (res) {
        setAddresses(res?.data?.additionaladdress);
      } else {
        CustomToaster.error(res.message);
      }
    });
  };

  React.useEffect(() => {
    setAddresses(data?.additionaladdress);
    const primaryLocations = data?.additionaladdress?.filter(
      (location) => location.is_primary === 1
    )[0];
    if (selectedAddress != primaryLocations && !addressId) {
      onSubmit(primaryLocations);
      setSelectedAddress(primaryLocations);
    }
    return;
  }, [addresses, data]);

  React.useEffect(() => {
    if (addressId) {
      const location = data?.additionaladdress?.filter((location) => {
        console.log(location.id);
        // location.id === addressId
      })[0];
      setSelectedAddress(location);
    }
  }, [addressId]);

  return (
    <>
      <Box
        sx={{
          padding: 0,
          m: 1,
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Typography variant="body1">{selectedAddress?.street}</Typography>
          <Typography variant="body1">
            {`${selectedAddress?.city}, ${selectedAddress?.state} ${
              selectedAddress?.country || ""
            }`}
          </Typography>
          <Typography variant="body1">{selectedAddress?.pin_code}</Typography>
        </Box>

        <IconButton
          onClick={() => {
            handleClickOpenMd();
          }}
        >
          <EditIcon />
        </IconButton>
      </Box>

      <Dialog
        fullScreen
        open={openMd}
        onClose={handleCloseMd}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 0.5, flex: 1, display: "flex" }}
              component="div"
            >
              <Typography
                sx={{ alignSelf: "flex-end" }}
                component={"span"}
                variant="h6"
              >
                {label}
              </Typography>
            </Typography>

            <IconButton
              sx={{ mr: -2 }}
              edge="end"
              color="inherit"
              onClick={handleCloseMd}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ m: 2 }}>
          {addresses.map((item, i) => (
            <>
              <Box
                sx={{
                  padding: 0,
                  m: 1,
                  my: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  border: 0.05,
                  p: 1,
                  borderRadius: 1,
                }}
              >
                <Box
                  onClick={() => {
                    handleChange(item);
                  }}
                >
                  {selectedAddress?.id === item?.id ? (
                    <>
                      <RadioButtonCheckedIcon sx={{ fill: "blue" }} />
                    </>
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}

                  <Typography variant="body1">{item?.street}</Typography>
                  <Typography variant="body1">
                    {`${item?.city}, ${item?.state} ${item?.country || ""}`}
                  </Typography>
                  <Typography variant="body1">{item?.pin_code}</Typography>
                </Box>
                {hideEdit ? (
                  ""
                ) : (
                  <UpdateAddress
                    onUpdate={fetchLatestAddress}
                    address={[item]}
                  />
                )}
                {/* <IconButton
                // onClick={() => {
                //     handleClickOpenMd();
                // }}
                >
                  <EditIcon />
                </IconButton> */}
              </Box>
            </>
          ))}
        </Box>
      </Dialog>
    </>
  );
}
