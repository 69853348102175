import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CustomDatePicker,
  CustomDialogWrapper,
  CustomTimePicker,
  NoResultFound,
} from "../../../../components";
import CustomButton from "../../../../components/ActionButtons/CustomButton";
import { ThemeColors } from "../../../../constant/ThemeColors";
import {
  Add,
  Check,
  Delete,
  Done,
  Edit,
  EditAttributesRounded,
  EditNote,
} from "@mui/icons-material";
import { useFormik } from "formik";
import ApiClient from "../../../../constant/API_URLS";
import { CustomTextField } from "../../../../components/InputFields/TextFields";
import CustomUnitSelect from "../../../../components/InputFields/SelectFields/CustomUnitSelect";
import {
  arrayValidationSchema,
  dateValidationSchema,
  numericValidationSchema,
  stringValidationSchema,
} from "../../../../constant/validationSchema";
import * as Yup from "yup";
import CustomToaster from "../../../../utils/CustomToaster";
import CustomAutoComplete from "../../../../components/InputFields/SelectFields/CustomAutoComplete";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import dayjs from "dayjs";
import { ReadableDate } from "../../../../utils/ReadableDateTime";
import { LoadingEffect } from "../../../../components/SimmerEffects/LoadingEffect";
import { DeleteConfirmationDialog } from "../../../../components/DeleteDetails/DeleteConfirmationDialog";

function createData(name, startDate, dueDate) {
  return { name, startDate, dueDate };
}

const CreateTodo = ({
  id,
  onClose,
  onSubmit,
  teamList,
  data = {},
  update = false,
}) => {
  const formik = useFormik({
    initialValues: {
      jobid: id,
      task: "",
      assignees: [],
      todo_start_date: "",
      todo_end_date: "",
      ...data,
    },
    validationSchema: Yup.object().shape({
      task: stringValidationSchema,
      todo_start_date: dateValidationSchema,
      todo_end_date: dateValidationSchema,
      assignees: arrayValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      if (update) {
        updateTodo(values, resetForm);
      } else {
        createTodo(values, resetForm);
      }
    },
  });

  const createTodo = (data, resetForm) => {
    ApiClient.todo
      .post(data)
      .then((res) => {
        CustomToaster.success(res?.message);
        onClose();
        resetForm();
        onSubmit();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTodo = (data, resetForm) => {
    console.log(data);
    ApiClient.todo
      .put(id, data)
      .then((res) => {
        CustomToaster.success(res?.message);
        onClose();
        resetForm();
        onSubmit();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
        mt: 1,
        mx: 2,
        pb: 1,
      }}
      onSubmit={formik.handleSubmit}
    >
      <Box mb={2} sx={{ width: "100%" }}>
        <Typography style={{ fontWeight: "500" }}> Checklist: </Typography>
        <CustomTextField
          placeholder="Write your checklist here..."
          form={formik}
          field={formik.getFieldProps("task")}
        />
      </Box>

      <Box sx={{ width: "100%", mt: 1 }}>
        <Typography style={{ fontWeight: "500" }}> Assignees: </Typography>

        <Autocomplete
          multiple
          disableCloseOnSelect
          limitTags={1}
          id="assigned-team-list"
          onChange={(e, option) => {
            let selected = option?.map((item) => {
              return item.value;
            });
            formik?.setFieldValue("assignees", selected);
          }}
          defaultValue={
            update
              ? teamList.filter((team) =>
                  data?.assignees.some((value) => value == team.value)
                )
              : []
          }
          options={teamList}
          renderInput={(params) => {
            return (
              <TextField
                placeholder={
                  params?.InputProps?.endAdornment?.props?.children[0]
                    ? ""
                    : "Select"
                }
                {...params}
                error={
                  !!(
                    formik?.touched["assignees"] && formik?.errors["assignees"]
                  )
                }
                helperText={
                  formik?.touched["assignees"] && formik?.errors["assignees"]
                }
              />
            );
          }}
        />
      </Box>

      <Box sx={{ width: "100%", mt: 1, display: "flex", gap: 2 }}>
        <Box sx={{ width: "100%", mt: 2 }}>
          <Typography style={{ fontWeight: "500" }}> Start date: </Typography>
          <CustomDatePicker form={formik} name="todo_start_date" />
        </Box>
        <Box sx={{ width: "100%", mt: 2 }}>
          <Typography style={{ fontWeight: "500" }}> Due date: </Typography>
          <CustomDatePicker form={formik} name="todo_end_date" />
        </Box>
      </Box>

      <Button
        fullWidth
        variant="contained"
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          //   justifyContent: "flex-end",
          gap: 1,
          mt: 3,
          py: 1.2,
          maxWidth: "90%",
        }}
        type="submit"
      >
        {update ? "Update" : " Add Checklist"}
      </Button>

      <Box sx={{ display: { md: "none" } }} item xs={12} width={"100%"}>
        <CustomButton disabled={formik?.isSubmitting}  label={update ? "Update" : " Add Checklist"} />
      </Box>
    </Box>
  );
};

const TableRender = ({ todoList, team, onSubmit }) => {
  const teamList = {};
  // Loop through the array and create the key-value pairs
  team.forEach((item) => {
    teamList[`${item.value}`] = item.label;
  });

  const handleDelete = (id) => {
    ApiClient.todo
      .delete(id)
      .then((res) => {
        if (res) {
          CustomToaster.success(res?.message);
          onSubmit();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box sx={{ my: 2 }}>
      <TableContainer component={Paper} sx={{ maxHeight: 700 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ width: "60%", "text-wrap": "nowrap" }}
              ></TableCell>
              <TableCell
                sx={{ fontWeight: "700", fontSize: 16, "text-wrap": "nowrap" }}
              >
                Team Member
              </TableCell>
              <TableCell
                sx={{ fontWeight: "700", fontSize: 16, "text-wrap": "nowrap" }}
                align="right"
              >
                Start date:
              </TableCell>
              <TableCell
                sx={{ fontWeight: "700", fontSize: 16, "text-wrap": "nowrap" }}
                align="right"
              >
                Due date:
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {todoList.map((todo) => (
              <TableRow
                key={todo?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{ "text-wrap": "nowrap" }}
                  component="th"
                  scope="row"
                >
                  {todo?.task}
                </TableCell>
                <TableCell
                  component="th"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    whiteSpace: "nowrap",
                    gap: 1,
                  }}
                >
                  {todo?.assignees?.map((member) => (
                    <>
                      {console.log(
                        todo?.jobcomplete_assignee?.includes(Number(member))
                      )}
                      <Chip
                        sx={{ width: "max-content" }}
                        label={teamList[member]}
                        onDelete={() => {}}
                        deleteIcon={
                          todo?.jobcomplete_assignee?.includes(
                            Number(member)
                          ) ? (
                            <Done />
                          ) : (
                            <></>
                          )
                        }
                        color={
                          todo?.jobcomplete_assignee?.includes(Number(member))
                            ? "success"
                            : "default"
                        }
                        size="small"
                        variant="outlined"
                      />
                    </>
                  ))}
                </TableCell>
                <TableCell align="right">
                  {ReadableDate(todo?.todo_start_date)}
                </TableCell>
                <TableCell align="right">
                  {ReadableDate(todo?.todo_end_date)}
                </TableCell>
                <TableCell align="right">
                  <Typography
                    sx={{
                      display: "flex",
                      justifyItems: "flex-end",
                      justifyContent: "flex-end",
                      gap: 1,
                    }}
                  >
                    <CustomDialogWrapper
                      title="Update Checklist"
                      element={
                        <Tooltip title="Update">
                          <EditNote
                            sx={{
                              ":hover": {
                                cursor: "pointer",
                              },
                            }}
                            color="action"
                          />
                        </Tooltip>
                      }
                    >
                      <CreateTodo
                        id={todo?.id}
                        teamList={team}
                        onSubmit={onSubmit}
                        data={todo}
                        update
                      />
                    </CustomDialogWrapper>

                    <DeleteConfirmationDialog
                      onSubmit={() => {
                        handleDelete(todo?.id);
                      }}
                    >
                      <Tooltip title="Delete">
                        <Delete
                          sx={{
                            ":hover": {
                              cursor: "pointer",
                            },
                          }}
                          color="error"
                        />
                      </Tooltip>
                    </DeleteConfirmationDialog>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

function TodoTab({ todoList, fetchTodo, jobDetail }) {
  const options = jobDetail?.team_details?.map((item) => ({
    label: item?.first_name + " " + item?.last_name,
    value: item.id,
  }));

  return (
    <Box sx={{ paddingY: 2.5 }}>
      <CustomDialogWrapper
        title="Add Checklist"
        element={
          <Box
            sx={{
              border: 0.5,
              borderRadius: 1,
              borderStyle: "dotted",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 0.6,
              px: 2,
              width: "max-content",
              color: ThemeColors.secondaryText,
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <Add />
            <Typography variant="subtitle1" sx={{ fontSize: 18 }}>
              Add a Checklist
            </Typography>
          </Box>
        }
      >
        <CreateTodo
          id={jobDetail?.job_id}
          teamList={options}
          onSubmit={fetchTodo}
        />
      </CustomDialogWrapper>

      {!todoList ? (
        <LoadingEffect />
      ) : (
        <>
          {todoList?.length ? (
            <>
              <TableRender
                todoList={todoList}
                onSubmit={fetchTodo}
                team={options}
              />
            </>
          ) : (
            <>
              <NoResultFound>No Checklist Found</NoResultFound>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default TodoTab;
