import React, { useEffect, useState } from 'react'
import { NavBar, NoResultFound } from '../../components'
import { Box } from '@mui/material'
import { TextArrowCard } from '../../components/Cards/DisplayCards'
import ApiClient from '../../constant/API_URLS'
import { AddCustomText } from '../../components/AddAdditionalContact'
import { LoadingEffect } from '../../components/SimmerEffects/LoadingEffect'
import { UpdateText } from '../../components/UpdateDetails/UpdateText'
import { DeleteConfirmationDialog } from '../../components/DeleteDetails'
import CustomToaster from '../../utils/CustomToaster'

export const Services = () => {
  const [catergory, setCategory] = useState('')

  const fetchDetail = () => {
    ApiClient.serviceCategory.get().then(res => {
      setCategory(res?.data)
    }).catch(err => {
      console.log(err);
    })
  }


  const updateCategory = (data, handleClose, rest) => {
    ApiClient.serviceCategory.put(rest?.id, data).then((res) => {
      if (res) {
        CustomToaster.success(res?.message);
        handleClose();
        fetchDetail();
      }
    });
  };

  const deleteCategory = (id, handleClose, rest) => {
    ApiClient.serviceCategory.delete(id).then((res) => {
      if (res) {
        CustomToaster.success(res?.message);
        handleClose();
        fetchDetail();
      }
    });
  };





  useEffect(() => {
    fetchDetail()
  }, [])


  return (
    <>
      <NavBar title='Industry' noFilter noSearch />
      <Box mt={2} >

        {!catergory ? (
          <LoadingEffect />
        ) : (
          <>
            {catergory?.length ? (
              <>
                {catergory?.map((cat) => <TextArrowCard key={cat.id} title={cat.name} url={cat.id}
                  onUpdate={
                    <UpdateText
                      id={cat.id}
                      name={"name"}
                      value={cat.name}
                      iconText="Edit "
                      onSubmit={updateCategory}
                      label="Category"
                    />
                  }
                  onDelete={
                    <DeleteConfirmationDialog
                      id={cat.id}
                      name={"name"}
                      value={cat.name}
                      iconText="Delete"
                      onSubmit={deleteCategory}
                      label="Category"
                    />
                  }

                />)}
              </>
            ) : (
              <>
                <NoResultFound>
                  No Result Found
                </NoResultFound>

              </>
            )}
          </>
        )}

        <AddCustomText title={'Add Industry'} label={'Industry'} api={ApiClient.serviceCategory} name={'name'} onUpdate={fetchDetail} />

      </Box>

    </>
  )
}
