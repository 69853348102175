import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Avatar,
  Box,
  Button,
  Pagination,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import ApiClient from "../../../constant/API_URLS";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { useSelector } from "react-redux";
import { Filter1, KeyboardArrowRight } from "@mui/icons-material";
import { ThemeColors } from "../../../constant/ThemeColors";
import React, { useEffect, useState } from "react";
import {
  CustomDatePicker,
  CustomDialogWrapper,
  NoResultFound,
  TeamSelectRadioCard,
} from "../../../components";
import dayjs from "dayjs";
import { ReadableDateTime } from "../../../utils/ReadableDateTime";
import MapDisplay from "../../../components/Cards/DisplayCards/MapDisplay";
import { useFormik } from "formik";
import FilterAlt from "@mui/icons-material/FilterAlt";
import CustomButton from "../../../components/ActionButtons/CustomButton";

const tdWidth = {
  width: 100,
  minWidth: 100,
  maxWidth: 200,
};

export default function GroupByJobTable() {
  const [timeSheet, setTimeSheet] = useState("");
  const [pagination, setPagination] = useState("");

  const [filterValues, setFilterValues] = useState("&ids=[]&from=&to=");
  const fetchTimeSheet = (filter = "?page=1") => {
    ApiClient.timeSheetJobWise
      ?.get(filter + filterValues)
      ?.then((res) => {
        if (res) {
          setTimeSheet(res?.data);
          setPagination(res?.pagination);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTimeSheet();
  }, [filterValues]);

  const [initialValues, setInitialValues] = useState({
    from: "",
    to: "",
    ids: [],
  });

  const RenderFilter = ({ onClose }) => {
    function extractIds(data) {
      return data?.map((item) => item.id);
    }
    const formik = useFormik({
      initialValues,
      onSubmit: (values, { resetForm }) => {
        onClose();
        setInitialValues({ ...values });
        const idsArray = extractIds(values?.ids);
        setFilterValues(
          `&ids=[${idsArray}]&from=${
            values.from ? dayjs(values.from)?.format("YYYY-MM-DD") : ""
          }&to=${values.to ? dayjs(values.to)?.format("YYYY-MM-DD") : ""}`
        );
      },
    });

    return (
      <Box sx={{ p: 2 }}>
        <Typography sx={{ mb: 0 }}>Date Range</Typography>
        <Box display={"flex"} rowGap={1} gap={1}>
          <CustomDatePicker form={formik} name="from" />
          <CustomDatePicker form={formik} name="to" />
        </Box>
        <Typography sx={{ mt: 1 }}>Team</Typography>
        <TeamSelectRadioCard
          form={formik}
          name={"ids"}
          alreadyTeam={formik.values.ids}
        />

        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Button
            onClick={formik?.handleSubmit}
            variant="contained"
            size="large"
            sx={{ margin: "auto", my: 4, width: "60%" }}
          >
            Search
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <CustomDialogWrapper
        title="Filter"
        element={
          <IconButton
            sx={{
              position: "absolute",
              top: 24,
              right: 16,
              background: colors.grey[400],
              ":hover": {
                background: colors.grey[500],
              },
            }}
          >
            <FilterAlt htmlColor="white" />
          </IconButton>
        }
      >
        <RenderFilter />
      </CustomDialogWrapper>

      {!timeSheet ? (
        <LoadingEffect />
      ) : (
        <>
          {" "}
          {timeSheet && !timeSheet?.length ? (
            <NoResultFound>No Record Found</NoResultFound>
          ) : (
            <>
              <Table
                stickyHeader
                sx={{
                  p: 2,
                  borderWidth: "2px solid",
                }}
                aria-label="sticky table"
              >
                <TableHead sx={{ margin: 2, padding: 2 }}>
                  <TableRow>
                    <TableCell
                    // sx={{ minWidth: tdWidth?.minWidth, width: tdWidth }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      // sx={{ minWidth: tdWidth?.minWidth, width: tdWidth }}
                      align="left"
                    >
                      Team
                    </TableCell>
                    <TableCell
                      // sx={{ minWidth: tdWidth?.minWidth, width: tdWidth }}
                      align="right"
                    >
                      Start Time
                    </TableCell>
                    <TableCell
                      // sx={{ minWidth: tdWidth?.minWidth, width: tdWidth }}
                      align="right"
                    >
                      End Time
                    </TableCell>
                    <TableCell
                      // sx={{ minWidth: tdWidth?.minWidth, width: tdWidth }}
                      align="right"
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timeSheet?.map((item) => (
                    <Row item={item} />
                    // <TableRow>
                    //   <TableCell
                    //     sx={{ borderBottom: "unset", padding: 0 }}
                    //     colSpan={5}
                    //   >
                    //     <TableContainer
                    //       sx={{
                    //         minWidth: 600,
                    //         padding: 0,
                    //         mt: 2,
                    //         borderRadius: 2,
                    //         border: 0.5,
                    //         borderBottom: 2,
                    //         // borderColor: '#E1EDFF',
                    //         borderColor: colors.blue[300],
                    //         boxShadow: ThemeColors.boxShadow,
                    //       }}
                    //     >
                    //       <Table aria-label="collapsible table">
                    //         <TableBody>
                    //           <Row item={item} />
                    //         </TableBody>
                    //       </Table>
                    //     </TableContainer>
                    //   </TableCell>
                    // </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  mt: 4,
                }}
              >
                <Pagination
                  onChange={(e, value) => {
                    fetchTimeSheet(`?page=${value}`);
                  }}
                  size="large"
                  page={pagination?.current_page}
                  count={pagination?.last_page}
                  color="primary"
                />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
function Row(props) {
  const { item } = props;
  const [open, setOpen] = React.useState(true);

  function calculateDiffrence(teamMember) {
    const startTime = new Date(teamMember.sheet_start_date);
    const endTime = new Date(teamMember.sheet_end_date);
    const diff = Math.round((endTime - startTime) / 1000);
    return isNaN(diff) ? 0 : diff;
  }
  function calculateTotalTime(data) {
    let totalTime = 0;
    data.forEach((job) => {
      job.team_info.forEach((teamMember) => {
        console.log(calculateDiffrence(teamMember));
        totalTime += calculateDiffrence(teamMember);
      });
    });
    return totalTime;
  }
  function formatTime(totalSeconds) {
    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    const timeParts = [];
    if (days > 0) {
      timeParts.push(`${days}`);
    }
    if (hours > 0) {
      timeParts.push(`${hours}`);
    }
    if (minutes > 0) {
      timeParts.push(`${minutes}`);
    }
    if (seconds > 0 || timeParts.length === 0) {
      timeParts.push(`${seconds}`);
    }

    return timeParts.join(":");
  }

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={() => setOpen(!open)}
        sx={{ background: "#E1EDFF" }}
      >
        <TableCell colSpan={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}

            <Avatar
              sx={{
                textTransform: "uppercase",
                fontSize: 16,
                width: 26,
                height: 26,
              }}
            >
              {item?.job_name?.slice(0, 1)}
            </Avatar>
            <Typography sx={{ textTransform: "capitalize" }}>
              {item?.job_name} ({item?.job_id})
            </Typography>
          </Box>
        </TableCell>
        <TableCell
          sx={{
            minWidth: tdWidth?.minWidth,
            width: tdWidth,
            whiteSpace: "nowrap",
          }}
          align="right"
        >
          {formatTime(calculateTotalTime(item?.sheet))}
        </TableCell>
      </TableRow>

      <>
        {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
        {item?.sheet?.map((sheetItem) => {
          let length = sheetItem?.team_info?.length;
          return (
            <>
              {sheetItem?.team_info?.map((item, index) => (
                <>
                  {open ? (
                    <TableRow
                      hover={index}
                      sx={{
                        "&>*": {
                          borderBottom: index === length ? "inherit" : "unset",
                        },
                      }}
                    >
                      {!index && (
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            "&>*": {
                              borderBottom: "unset",
                            },
                          }}
                          align="left"
                          rowSpan={length}
                        >
                          {dayjs(sheetItem?.job_work_date).format("MMM DD, YYYY")}
                        </TableCell>
                      )}

                      <TableCell
                        sx={{
                          borderBottom: "unset",
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignItems: "center",
                        }}
                        align="left"
                      >
                        <MapDisplay item={item} /> {item?.user_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "unset",
                          whiteSpace: "nowrap",
                        }}
                        align="right"
                      >
                        {dayjs(item?.sheet_start_date).format("hh:mm:ss a")}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "unset",
                          whiteSpace: "nowrap",
                        }}
                        align="right"
                      >
                        {item?.sheet_end_date
                          ? dayjs(item?.sheet_end_date).format("hh:mm:ss a")
                          : dayjs(item?.sheet_start_date).format("hh:mm:ss a")}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "unset",
                        }}
                        align="right"
                      >
                        {formatTime(calculateDiffrence(item))}
                      </TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </>
          );
        })}
        {/* </Collapse> */}
      </>

      {/* <TableRow>
        <TableCell
          align="right"
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                {item?.sheet?.map((item) => (
                  <TableRow>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {dayjs(item?.job_work_date).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell align="right" colSpan={4}>
                      {item?.team_info?.map((item) => (
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                            <TableRow sx={{ "&>*": { borderBottom: "unset" } }}>
                              <TableCell
                                sx={{
                                  // minWidth: tdWidth?.minWidth,
                                  width: tdWidth?.width,
                                  // maxWidth:tdWidth?.maxWidth,
                                  borderBottom: "unset",
                                  whiteSpace: "nowrap",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                align="left"
                              >
                                <MapDisplay item={item} /> {item?.user_name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  minWidth: tdWidth?.minWidth,
                                  width: tdWidth?.width,
                                  maxWidth: tdWidth?.maxWidth,
                                  borderBottom: "unset",
                                  whiteSpace: "nowrap",
                                }}
                                align="right"
                              >
                                {dayjs(item?.sheet_start_date).format(
                                  "hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  minWidth: tdWidth?.minWidth,
                                  width: tdWidth?.width,
                                  maxWidth: tdWidth?.maxWidth,
                                  borderBottom: "unset",
                                  whiteSpace: "nowrap",
                                }}
                                align="right"
                              >
                                {dayjs(item?.sheet_end_date).format(
                                  "hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  minWidth: tdWidth?.minWidth,
                                  width: tdWidth?.width,
                                  maxWidth: tdWidth?.maxWidth,
                                  borderBottom: "unset",
                                }}
                                align="right"
                              >
                                {formatTime(calculateDiffrence(item))}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}
