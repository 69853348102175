import { Card, IconButton, Paper } from "@mui/material";
import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  LocationCityRounded,
  LocationOn,
  PersonPinCircle,
} from "@mui/icons-material";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
  InfoWindowF,
} from "@react-google-maps/api";
const MapDisplay = ({ item }) => {
  const latitude = parseFloat(item?.sheet_lat) || 28.38853548750527;
  const longitude = parseFloat(item?.sheet_long) || 77.3485941195386;

  const mapUrl = `https://www.google.com/maps/embed/v1/view?key=AIzaSyBd0PYN77escnzaFd5L_kjgZOMweC8jJo4&center=${latitude},${longitude}&zoom=15&marker=${latitude},${longitude}`;

  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 18,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBd0PYN77escnzaFd5L_kjgZOMweC8jJo4",
  });

  const [activeMarker, setActiveMarker] = React.useState(null);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <BasicPopover>
      <Card
        elevation={24}
        sx={{
          //   width: "max-content",
          // height: "300px",
          // width: "400px",
          // padding: 1,
          borderRadius: 1,
        }}
      >
        <GoogleMap
          center={defaultProps?.center}
          zoom={defaultProps.zoom}
          mapContainerStyle={{ width: "400px", height: "300px" }}
          options={{
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: false,
            fullscreenControl: true,
          }}
        >
          <Marker
          
            position={defaultProps?.center}
          >
          </Marker>
        </GoogleMap>
      </Card>
    </BasicPopover>
  );
};

function BasicPopover({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <LocationOn />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {children}
      </Popover>
    </div>
  );
}

export default MapDisplay;
