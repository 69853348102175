
function calculateTotal(jobData) {
    let total = 0;

    if (jobData.materials && Array.isArray(jobData.materials)) {
      jobData.materials.forEach((material) => {
        if (material.total && material.unit_price && material.unit_count) {
          // total += parseFloat(material.total);
          const unitPrice = parseFloat(material.unit_price);
          const unitCount = parseFloat(material.unit_count);
          total += unitPrice * unitCount;
        }
      });
    }
    if (jobData.services && Array.isArray(jobData.services)) {
      jobData.services.forEach((service) => {
        if (service.total && service.unit_price && service.unit_count) {
          // total += parseFloat(service.total);
          const unitPrice = parseFloat(service.unit_price);
          const unitCount = parseFloat(service.unit_count);
          total += unitPrice * unitCount;
        }
      });
    }

    let rate = jobData?.tax_percent_rate || 0;
    let tax = ((Number(total) * Number(rate))/100);
    return {total:Number(total)+tax, tax, subTotal:total}


  }


  export {calculateTotal};