import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, DialogActions, DialogContent, DialogTitle, Fab, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { emailValidationSchema, phoneValidationSchema, stringValidationSchema } from "../../../constant/validationSchema";
import { CustomTextField } from "../TextFields";
import CustomButton from "../../ActionButtons/CustomButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import AddIcon from "@mui/icons-material/Add";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

export function AddCustomText({ initialValues = {}, title, label, name, id = '', onUpdate, api, onClick }) {

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [openMd, setOpenMd] = React.useState(false);
    const handleClickOpenMd = () => {
        setOpenMd(true);
    };
    const handleCloseMd = () => {
        setOpenMd(false);
    };


    const addNew = (values, resetForm) => {
        api.post(values, id).then(res => {
            if (res) {
                resetForm()
                CustomToaster.success(res.message)
                handleClose();
                handleCloseMd();
                onUpdate()
            }
        }).catch(err => {
            CustomToaster.error(err?.message)
        })

    }



    const formik = useFormik({
        initialValues: {
            ...initialValues,
            [name]: "",
        },
        validationSchema: Yup.object().shape({
            [name]: stringValidationSchema,
        }),
        onSubmit: (values, { resetForm }) => {
            addNew(values, resetForm)
        },

    });

    // console.log(formik);



    return (
        <>

            <Fab color="primary"
                sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2),
                    transition: "ease",
                }}
                onClick={() => {
                    if (window.innerWidth < 900) {
                        handleClickOpen()
                    } else {
                        handleClickOpenMd()
                    }
                }
                }
            >
                <AddIcon />
            </Fab>


            <Dialog fullWidth open={openMd} onClose={handleCloseMd}>
                <DialogTitle>{title || label}</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "center",
                            alignItems: "center",
                            width: '100%',
                        }}
                        onSubmit={formik.handleSubmit}
                    >
                        <Grid container padding={2} justifyContent="center">
                            <Grid xs={12} item >
                                <CustomTextField
                                    form={formik}
                                    field={formik.getFieldProps(name)}
                                    margin="normal"
                                    label={label}
                                />
                            </Grid>
                        </Grid>


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMd}>Cancel</Button>
                    <Button onClick={formik.handleSubmit}>Save</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>

                        <Typography sx={{ ml: 0.5, flex: 1 }} variant="h6" component="div">
                            {title || label}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>

                    </Toolbar>
                </AppBar>

                <Box
                    component="form"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        alignItems: "center",
                        width: '100%',
                    }}
                    onSubmit={formik.handleSubmit}
                >
                    <Grid container padding={2} justifyContent="center">
                        <Grid xs={12} item >
                            <CustomTextField
                                form={formik}
                                field={formik.getFieldProps(name)}
                                margin="normal"
                                label={label}
                            />
                        </Grid>

                        <CustomButton disabled={formik?.isSubmitting}  label={"Save"} />
                    </Grid>

                </Box>

            </Dialog>

        </>
    );
}
