import React, { useEffect, useState } from "react";
import { JobDetailContainerListCard, NavBar } from "../../../components";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  colors,
} from "@mui/material";
import { ThemeColors } from "../../../constant/ThemeColors";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ApiClient from "../../../constant/API_URLS";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { ReadableDate } from "../../../utils/ReadableDateTime";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import ReadableAddress from "../../../utils/ReadableAddress";
import { CloudDownload } from "@mui/icons-material";

function InvoiceDetail() {
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invoiceCreate = searchParams.get("invoiceCreate");

  const [invoiceDetail, setInvoiceDetail] = useState("");

  const fetchInvoice = () => {
    ApiClient.invoice
      .get("?invoice_id=" + invoiceId)
      .then((res) => {
        if (res) {
          setInvoiceDetail(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createInvoice = () => {
    ApiClient.invoice
      .post({}, invoiceCreate)
      .then((res) => {
        if (res) {
          navigate("/invoices/detail/" + res?.data?.invoice_id,{ replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (invoiceCreate) {
      createInvoice();
    } else {
      fetchInvoice();
    }
  }, [invoiceId]);


  
  function calculateTotal(jobData) {
    let total = 0;

    if (jobData.materials && Array.isArray(jobData.materials)) {
      jobData.materials.forEach((material) => {
        if (material.total && material.unit_price && material.unit_count) {
          // total += parseFloat(material.total);
          const unitPrice = parseFloat(material.unit_price);
          const unitCount = parseFloat(material.unit_count);
          total += unitPrice * unitCount;
        }
      });
    }
    if (jobData.services && Array.isArray(jobData.services)) {
      jobData.services.forEach((service) => {
        if (service.total && service.unit_price && service.unit_count) {
          // total += parseFloat(service.total);
          const unitPrice = parseFloat(service.unit_price);
          const unitCount = parseFloat(service.unit_count);
          total += unitPrice * unitCount;
        }
      });
    }

    let rate = jobData?.Job_details?.tax_percent_rate || 0;
    let tax = ((Number(total) * Number(rate))/100);
    return {total:Number(total)+tax, tax, subTotal:total}



  }

  return (
    <>
      <NavBar
        goBack
        noFilter
        noSearch
        title={"Invoice #" + (invoiceCreate ? "generating..." : invoiceId)}
        onRight={
          <>
            {invoiceDetail && invoiceDetail?.invoice_link && (
              <Button
                download={true}
                href={invoiceDetail?.invoice_link}
                component={"a"}
                style={{ textTransform: "capitalize", color: "#fff" }}
                variant="text"
                startIcon={<CloudDownload />}
              >
                Download
              </Button>
            )}
          </>
        }
      />

      {invoiceDetail ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={"/assets/static/img/logo.png"} height={180} />
          </Box>
          <Divider sx={{ mx: 1 }} />
          <Box
            mx={1}
            sx={{
              // color: ThemeColors.textColor,
              fontSize: 18,
              fontWeight: "600",
              p: 1.2,
              borderRadius: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              {" "}
              <Typography component={"span"} sx={{ fontWeight: 500 }}>
                Date:{" "}
              </Typography>
              {ReadableDate(invoiceDetail?.created_at)}
            </Typography>

            <Typography sx={{ width: "32.5%" }}>
              {" "}
              <Typography component={"span"} sx={{ fontWeight: 500 }}>
                Invoice No:{" "}
              </Typography>
              {invoiceDetail?.invoice_number}
            </Typography>
          </Box>
          <Divider sx={{ mx: 1, mb: 2 }} />

          <Box
            component={"div"}
            p={1}
            sx={{
              display: {
                xs: "block",
                lg: "flex",
              },
              justifyContent: "space-between",
              gap: 12,
            }}
          >
            <Box sx={{ width: { lg: "33%" } }}>
              <Typography
                sx={{
                  // color: ThemeColors.textColor,
                  fontSize: 18,
                  fontWeight: "600",
                  background: colors.blue[50],
                  p: 1.2,
                  borderRadius: 1,
                }}
              >
                Company Info
              </Typography>

              <Box sx={{ pl: 2 }}>
                <ListItemText
                  primaryTypographyProps={{ fontWeight: 600 }}
                  primary={
                    invoiceDetail?.admin_info?.company_name ||
                    `${invoiceDetail?.admin_info?.first_name} ${invoiceDetail?.admin_info?.last_name}`
                  }
                  secondary={ReadableAddress(invoiceDetail?.admin_info)}
                />
                <Typography>
                  {" "}
                  <Typography component={"span"} sx={{ fontWeight: 500 }}>
                    Contact Number:{" "}
                  </Typography>{" "}
                  {invoiceDetail?.admin_info?.phone}{" "}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                mt: {
                  xs: 6,
                  md: 0,
                },
                width: { lg: "33%" },
              }}
            >
              <Typography
                sx={{
                  // color: ThemeColors.textColor,
                  fontSize: 18,
                  fontWeight: "600",
                  background: colors.blue[50],
                  p: 1.2,
                  borderRadius: 1,
                }}
              >
                Bill To
              </Typography>

              <Box sx={{ pl: 2 }}>
                <ListItemText
                  primaryTypographyProps={{ fontWeight: 600 }}
                  primary={
                    invoiceDetail?.clientInfo?.company_name ||
                    `${invoiceDetail?.clientInfo?.first_name} ${invoiceDetail?.clientInfo?.last_name}`
                  }
                  secondary={ReadableAddress(invoiceDetail?.clientInfo)}
                />
                <Typography>
                  {" "}
                  <Typography component={"span"} sx={{ fontWeight: 500 }}>
                    Contact Number:{" "}
                  </Typography>{" "}
                  {invoiceDetail?.clientInfo?.phone}{" "}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 6, mx: 1, pb: 4 }}>
            <JobDetailContainerListCard
              hideCardButton
              hideListButton
              listData={invoiceDetail?.services}
              cardButton="service"
              cardTitle="Services"
            />
            {invoiceDetail?.materials?.length ? (
              <JobDetailContainerListCard
                hideCardButton
                hideListButton
                listData={invoiceDetail?.materials}
                cardButton="material"
                cardTitle="Material"
              />
            ) : (
              ""
            )}

            <Box
              sx={{
                overflow: "hidden",
                width: "100%",
                pr: 0.5,
                mb: 6,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  mt: 1,
                  border: 0.8,
                  borderColor: "#B7D4FF",
                  background: "#F8F9FC",
                  borderRadius: 1.6,
                  borderBottom: 3,
                  borderBottomColor: "#B7D4FF",
                  px: 1,
                }}
              >
                <Box sx={{ p: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography> Subtotal: </Typography>
                    <Typography>${calculateTotal(invoiceDetail)?.subTotal?.toFixed(2)}</Typography>
                  </Box>
                  <Divider sx={{ my: 1 }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography> Tax:{invoiceDetail?.Job_details?.tax_percent_rate || 0}% </Typography>
                    <Typography>${calculateTotal(invoiceDetail)?.tax?.toFixed(2)}</Typography>
                  </Box>
                  <Divider sx={{ my: 1 }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography> Grand Total: </Typography>
                    <Typography>${calculateTotal(invoiceDetail)?.total?.toFixed(2)}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <LoadingEffect />
      )}
    </>
  );
}

export default InvoiceDetail;
