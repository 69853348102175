import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  colors,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeColors } from "../../../../constant/ThemeColors";
import {
  ReadableDate,
  ReadableDateTime,
} from "../../../../utils/ReadableDateTime";
import ReadableAddress from "../../../../utils/ReadableAddress";
import {
  CallMadeSharp,
  CallOutlined,
  PersonPinCircleOutlined,
  Phone,
  PhoneAndroidSharp,
} from "@mui/icons-material";
import CalculateTotalServiceMaterial from "../../../../utils/CalculateTotalServiceMaterial";

export function InvoiceCard({ id, url = "/invoices/detail/", invoice }) {
  const navigate = useNavigate();
  function calculateTotal(jobData) {
    let total = 0;

    if (jobData.materials && Array.isArray(jobData.materials)) {
      jobData.materials.forEach((material) => {
        if (material.total && material.unit_price && material.unit_count) {
          // total += parseFloat(material.total);
          const unitPrice = parseFloat(material.unit_price);
          const unitCount = parseFloat(material.unit_count);
          total += unitPrice * unitCount;
        }
      });
    }
    if (jobData.services && Array.isArray(jobData.services)) {
      jobData.services.forEach((service) => {
        if (service.total && service.unit_price && service.unit_count) {
          // total += parseFloat(service.total);
          const unitPrice = parseFloat(service.unit_price);
          const unitCount = parseFloat(service.unit_count);
          total += unitPrice * unitCount;
        }
      });
    }

    let rate = jobData?.Job_details?.tax_percent_rate || 0;
    let tax = ((Number(total) * Number(rate)) / 100);
    // return {total:Number(total)+tax, tax, subTotal:total}

    return (Number(total) + tax).toFixed(2);
  }
  return (
    <>
      <Box
        onClick={() => {
          navigate(url + invoice?.invoice_number);
        }}
        px={1}
        borderRadius={1}
        boxShadow={"0 0 3px -3px"}
        width={{ md: "49%", xs: "100%" }}
        sx={{
          position: 'realtive',
          ":hover": {
            cursor: "pointer",
            boxShadow: ThemeColors.hoverBoxShadow,
          },
          border: 0.5,
          borderBottom: 4,
          borderColor: ThemeColors.borderColor,
        }}
      >
        <List dense>
          <ListItem sx={{ justifyContent: "space-between" }}>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 18,
                fontWeight: "600",
                textTransform: "capitalize",
              }}
              primary={invoice?.name}
              secondaryTypographyProps={{ textTransform: "capitalize" }}
              secondary={`Job Id: ${invoice?.job_id}`}
            />

            <ListItemText
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
              secondaryTypographyProps={{ fontWeight: "600", fontSize: 17 }}
              primary={ReadableDateTime(invoice?.created_at)}
              secondary={`$ ${calculateTotal(invoice)}`}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemAvatar sx={{ ml: -1, pl: 0 }}>
              <Avatar>{invoice?.clientInfo?.first_name?.charAt(0) + invoice?.clientInfo?.last_name?.charAt(0)}</Avatar>
            </ListItemAvatar>

            <ListItemText
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: "500",
                // color: "GrayText",
              }}
              primary={`${invoice?.clientInfo?.first_name} ${invoice?.clientInfo?.last_name} `}
              secondaryTypographyProps={{
                fontSize: 14,
                // color: "GrayText",
              }}
              secondary={ReadableAddress(invoice?.clientInfo)}
            />
            {/* <CallOutlined
              htmlColor="#fff"
              sx={{
                background: ThemeColors.primary,
                borderRadius: 100,
                fontSize: 36,
                padding: 0.7,
              }}
            /> */}
          </ListItem>
        </List>



      </Box>
    </>
  );
}
