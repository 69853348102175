import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  emailValidationSchema,
  numericValidationSchema,
  stringValidationSchema,
} from "../../constant/validationSchema";
import { CustomTextField } from "../InputFields/TextFields";
import CustomButton from "../ActionButtons/CustomButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";
import { MaterialCard, TextArrowCard } from "../Cards/DisplayCards";
import { NoResultFound } from "../NoResult/NoResult";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Navigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

export function AddMaterialActionButton({
  label = " Add Material to Job",
  onSubmit = false
}) {
  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
  };

  const [selected, setSelected] = React.useState({
    cat: null,
    subCat: [],
    material: null,
    materialIds: {},
  });


  // catergory handling
  const [catergory, setCategory] = React.useState("");
  const fetchCategory = () => {
    ApiClient.materialCategory.get().then((res) => {
      if (res) {
        setCategory(res?.data);
      }
    })
      .catch((err) => {
        console.log(err);
      });
  };


  //material list handling
  const [material, setMaterial] = React.useState("");
  const fetchmaterial = () => {
    ApiClient.material.get(selected.subCat[selected.subCat.length - 1]).then((res) => {
      if (res) {
        if (res?.data?.category_childlist?.length) {
          setSubCategory("");
          setSubCategory(res?.data?.category_childlist);
        } else if (res?.data?.metarial_list?.length) {
          setMaterial(res?.data?.metarial_list)
        } else {
          setSubCategory([]);
          setMaterial([])
        }
      }
    });
  };

  // Subcatergory handling
  const [subCatergory, setSubCategory] = React.useState("");
  const fetchSubCategory = () => {
    ApiClient.material.get(selected.cat).then((res) => {
      if (res) {
        if (res?.data?.category_childlist?.length) {
          setSubCategory(res?.data?.category_childlist)
        }
        if (res?.data?.metarial_list?.length) {
          setMaterial(res?.data?.metarial_list)
        }
      }
    });
  };




  React.useEffect(() => {
    if (selected.material) {
      if (onSubmit) {
        onSubmit(selected.materialIds);
        handleCloseMd();
        setSelected({
          cat: null,
          subCat: [],
          material: null,
          materialIds: {},
        });
        setMaterial('');
        setSubCategory('');
        setCategory('')


      }
    } else if (selected.subCat?.length) {
      fetchmaterial();
    } else if (selected.cat) {
      fetchSubCategory();
    } else {
      fetchCategory();
    }
  }, [selected]);


  return (
    <>
      <Typography
        onClick={() => {
          handleClickOpenMd();
        }}
        sx={{
          padding: 0,
          mt: -1,
          ":hover": {
            cursor: "pointer",
          },
          fontSize: 16,
          fontWeight: "500",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <AddCircleIcon htmlColor="#5E95FF" sx={{ p: 0.2 }} />
        Add Material
      </Typography>

      <Dialog
        fullScreen
        open={openMd}
        onClose={handleCloseMd}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 0.5, flex: 1, display: "flex" }}
              component="div"
            >
              {selected.cat ? (
                <IconButton
                  sx={{ mr: 0.8, ml: -1 }}
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    if (selected.material) {
                      setSelected((prevState) => ({
                        ...prevState,
                        material: null,
                        materialIds: {}
                      }));
                      setMaterial('');
                    } else if (selected.subCat?.length) {
                      setMaterial('');
                      setSelected((prevState) => {
                        let tempArray = prevState.subCat;
                        tempArray.pop();
                        return {
                          ...prevState,
                          subCat: tempArray,
                        }
                      });
                    } else if (selected.cat) {
                      setSelected((prevState) => ({ ...prevState, cat: null }));
                      setMaterial('');
                    }
                  }}
                  aria-label="close"
                  size="small"
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
              ) : (
                ""
              )}

              <Typography
                sx={{ alignSelf: "flex-end" }}
                component={"span"}
                variant="h6"
              >
                {label}
              </Typography>
            </Typography>

            <IconButton
              sx={{ mr: -2 }}
              edge="end"
              color="inherit"
              onClick={handleCloseMd}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ m: 2 }}>
          {
            // selected.material ? (
            //   <>
            //     <Box
            //       component={"form"}
            //       sx={{
            //         flex: 1,
            //         gap: 2,
            //         display: "flex",
            //         flexDirection: "column",
            //       }}
            //     >
            //       {Object.values(selected.materialIds)?.map((value, index) => (
            //         <EditableServiceCard key={material.id} service={value} />
            //       ))}
            //     </Box>
            //   </>
            // ) :
            selected?.subCat?.length || material ? (
              <>
                {!material ? (
                  <LoadingEffect />
                ) : (
                  <>
                    {material?.length ? (
                      <>
                      <Box sx={{display:'flex',flexWrap:'wrap', gap:{md:4}}}>
                        
                        {material?.map((material) => (
                          <MaterialCard
                            selectable
                            hideButton
                            selectedIds={selected.materialIds}
                            key={material.id}
                            onClick={(material, isSelected) => {
                              if (isSelected) {
                                setSelected((prevState) => {
                                  const updatedmaterialIds = {
                                    ...prevState.materialIds,
                                  };
                                  delete updatedmaterialIds[material.id];
                                  return {
                                    ...prevState,
                                    materialIds: updatedmaterialIds,
                                  };
                                });
                              } else {
                                setSelected((prevState) => ({
                                  ...prevState,
                                  materialIds: {
                                    ...prevState.materialIds,
                                    [material.id]: material,
                                  },
                                }));
                              }
                            }}
                            material={material}
                          />
                        ))}
                        
                      </Box>
                        <CustomButton
                          onClick={() => {
                            setSelected((prevState) => ({
                              ...prevState,
                              material: true,
                            }));


                          }}
                          label={"Continue"}
                        />
                      </>
                    ) : (
                      <>
                        <NoResultFound>No Material Found</NoResultFound>
                      </>
                    )}
                  </>
                )}
              </>
            ) : selected.cat ? (
              //SubCategory list here
              <>
                {!subCatergory ? (
                  <LoadingEffect />
                ) : (
                  <>
                    {subCatergory?.length ? (
                      <>
                        {subCatergory?.map((cat) => (
                          <TextArrowCard
                            key={cat.id}
                            title={cat.name}
                            onClick={() => {
                              setSelected((prevState) => {
                                let tempArray = prevState.subCat;
                                tempArray = [...tempArray, cat.id];
                                return {
                                  ...prevState,
                                  subCat: tempArray,
                                }
                              });
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <NoResultFound>No Material Found</NoResultFound>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              //category listing
              <>
                {!catergory ? (
                  <LoadingEffect />
                ) : (
                  <>
                    {catergory?.length ? (
                      <>
                        {catergory?.map((cat) => (
                          <TextArrowCard
                            key={cat.id}
                            title={cat.name}
                            onClick={() => {
                              setSelected((prevState) => ({
                                ...prevState,
                                cat: cat.id,
                              }));
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <NoResultFound>No Material Found</NoResultFound>
                      </>
                    )}
                  </>
                )}
              </>
            )}
        </Box>
      </Dialog>
    </>
  );
}
