import React, { useEffect, useState } from "react";
import { setPageTitle } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { NavBar, NoResultFound } from "../../components";
import ApiClient from "../../constant/API_URLS";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";
import { TextArrowCard } from "../../components/Cards/DisplayCards";
import { Box } from "@mui/material";
import { AddCustomText } from "../../components/AddAdditionalContact";
import { UpdateText } from "../../components/UpdateDetails/UpdateText";
import CustomToaster from "../../utils/CustomToaster";
import { DeleteConfirmationDialog } from "../../components/DeleteDetails";

export const Material = () => {
  const [materialCategory, setMaterialCategory] = useState("");

  const fetchDetail = () => {
    ApiClient.materialCategory
      .get()
      .then((res) => {
        if (res) {
          setMaterialCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCategory = (data, handleClose, rest) => {
    ApiClient.materialCategory.put(rest?.id, data).then((res) => {
      if (res) {
        CustomToaster.success(res?.message);
        handleClose();
        fetchDetail();
      }
    });
  };

  const deleteCategory = (id, handleClose, rest) => {
    ApiClient.materialCategory.delete(id).then((res) => {
      if (res) {
        CustomToaster.success(res?.message);
        handleClose();
        fetchDetail();
      }
    });
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  return (
    <>
      <NavBar title="Industry"  noFilter noSearch />

      <Box mt={2}>
        {!materialCategory ? (
          <LoadingEffect />
        ) : (
          <>
            {materialCategory?.length ? (
              <>
                {materialCategory?.map((cat) => (
                  <TextArrowCard
                    key={cat.id}
                    title={cat.name}
                    url={cat.id}
                    onUpdate={
                      <UpdateText
                        id={cat.id}
                        name={"name"}
                        value={cat.name}
                        iconText="Edit "
                        onSubmit={updateCategory}
                        label="Category"
                      />
                    }
                    onDelete={
                      <DeleteConfirmationDialog
                        id={cat.id}
                        name={"name"}
                        value={cat.name}
                        iconText="Delete"
                        onSubmit={deleteCategory}
                        label="Category"
                      />
                    }
                  />
                ))}
              </>
            ) : (
              <>
                <NoResultFound>No Result Found</NoResultFound>
              </>
            )}
          </>
        )}

        <AddCustomText
          title={"Add Industry"}
          label={"Industry"}
          api={ApiClient.materialCategory}
          name={"name"}
          onUpdate={fetchDetail}
        />
      </Box>
    </>
  );
};
