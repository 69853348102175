import React, { useEffect, useState } from "react";
import {
  ClientSelectRadioCard,
  CustomDatePicker,
  CustomTimePicker,
  JobDetailContainerListCard,
  NavBar,
  NoResultFound,
  RadioListFullPageWrapper,
  SelectAddressRadioCard,
  TeamSelectRadioCard,
} from "../../../components";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  arrayValidationSchema,
  numericValidationSchema,
  stringValidationSchema,
} from "../../../constant/validationSchema";
import { CustomTextField } from "../../../components/InputFields/TextFields";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import MergedDateAndTime from "../../../utils/MergedDateAndTime";
import { UploadFileOutlined } from "@mui/icons-material";
import { ThemeColors } from "../../../constant/ThemeColors";
import { CustomFileInput } from "../../../components/InputFields/CustomFileInput/CustomFileInput";
import { convertToFormData } from "../../../utils/ConvertToFormData";
import { transformMaterialArray, transformServiceArray } from "../../../utils/transformMaterialService";
import { quoteStatus } from "../../../constant/GlobalVariable";

export function QuoteUpdate() {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [material, setMaterial] = useState([]);

  const [quoteDetail, setquoteDetail] = useState("");
  const fetchquoteDetail = () => {
    ApiClient.quote.get("?quote_id=" + quote_id).then((res) => {
      if (res) {
        setquoteDetail(res.data[0]);
      } else {
        CustomToaster.error(res.message);
      }
    });
  };

  const [selectValue, setSelectValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");

  const formik = useFormik({
    initialValues: {
      materials: [],
      services: [],
      address_id: "",
      client_id: "",
      name: "",
      notes: "",
      ...quoteDetail,
    },
    validationSchema: Yup.object().shape({
      address_id: numericValidationSchema,
      client_id: numericValidationSchema,
      name: stringValidationSchema,
      notes: stringValidationSchema,
      services: arrayValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      updateQuote(values, resetForm);
    },
  });

  const updateQuote = (data, resetForm) => {
    let jobdata = {
      ...data,
      schedule_date: MergedDateAndTime(data.date, data.time),
      quote_status: new Date() >= MergedDateAndTime(data.date, data.time) ? quoteStatus.SENT : quoteStatus?.SCHEDULED
    };
    delete jobdata["time"];
    delete jobdata["date"];

    ApiClient.quote
      .put(quote_id, jobdata)
      .then((res) => {
        if (res) {
          CustomToaster.success(res.message);
          resetForm();
          setMaterial([]);
          setSelectValue("");
          setServices([]);
          navigate(-1);
        }
      });
  };

  const [subTotal, setSubTotal] = useState(0);
  const calculateSubTotal = () => {
    let subTotal = 0;
    for (const mat of formik.values?.materials) {
      const unitCount = parseInt(mat?.unit_count || 1);
      const purchasePrice = parseInt(mat?.unit_price);
      subTotal += unitCount * purchasePrice;
    }
    for (const service of formik.values?.services) {
      const unitCount = parseInt(service?.unit_count || 1);
      const purchasePrice = parseInt(service?.unit_price);
      subTotal += unitCount * purchasePrice;
    }
    setSubTotal(subTotal);
  };


  useEffect(() => {
    formik.setFieldValue("services", transformServiceArray(services));
    formik.setFieldValue("materials", transformMaterialArray(material));
    formik.setFieldValue("address_id", selectedAddress?.id);
    formik.setFieldValue("client_id", selectValue?.id);
    formik.setFieldValue("schedule_email", []);
  }, [material, services, selectValue, selectedAddress]);

  const { quote_id } = useParams();

  useEffect(() => {
    if (quoteDetail) {
      formik.setValues({
        ...quoteDetail,
      });
      formik.setFieldValue("address_id", Number(quoteDetail?.address_id));
      formik.setFieldValue("client_id", quoteDetail?.client_id);
      formik.setFieldValue("date", quoteDetail?.schedule_date);
      formik.setFieldValue("time", quoteDetail?.schedule_date);
      formik.setFieldValue("name", quoteDetail?.name);
      // formik.setFieldValue("notes", quoteDetail?.notes);
      setMaterial(quoteDetail?.materials);
      setServices(quoteDetail?.services);
    }
  }, [quoteDetail]);

  useEffect(() => {
    calculateSubTotal();
  }, [formik]);

  useEffect(() => {
    fetchquoteDetail();
  }, []);

  // radio button form data
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => setDialogOpen(false);

  const handleEmailSelection = (email) => {
    const allEmails = formik?.values?.schedule_email;
    const isExists = formik?.values?.schedule_email?.includes(email);
    if (!isExists) {
      formik.setFieldValue("schedule_email", [...allEmails, email]);
    } else {
      formik.setFieldValue(
        "schedule_email",
        allEmails?.filter((res) => res !== email)
      );
    }
  };


  const addTax = () => {
    let rate = quoteDetail?.quote_tax_rate || 0;
    let tax = (Number(subTotal) * Number(rate)) / 100;
    return {
      total: Number(subTotal) + (Number(subTotal) * Number(rate)) / 100,
      tax,
    };
  };


  return (
    <>
      <NavBar title="Update Quote" goBack noFilter noSearch />
      {!quoteDetail ? (
        <LoadingEffect />
      ) : (
        <Box sx={{ p: 1 }}>
          <ClientSelectRadioCard
            clientId={quoteDetail?.client_id}
            onSubmit={setSelectValue}
          />
          {selectValue?.id ? (
            <>
              <SelectAddressRadioCard
                data={selectValue}
                onSubmit={setSelectedAddress}
              />
              <Divider />

              <Box mt={4} mb={2}>
                <Typography sx={{ fontWeight: "500" }}>Name</Typography>
                <CustomTextField
                  // label="Job Name"
                  form={formik}
                  field={formik.getFieldProps("name")}
                />
              </Box>
              <JobDetailContainerListCard
                editable
                listData={services}
                setListData={setServices}
                cardButton="service"
              />
              <Typography
                variant="subtitle2"
                component={"span"}
                sx={{ color: "red" }}
              >
                {formik?.touched.services && formik?.errors?.services}
              </Typography>
              <JobDetailContainerListCard
                cardTitle="Material"
                editable
                listData={material}
                setListData={setMaterial}
                cardButton="material"
              />

              <Box
                sx={{
                  my: 3,
                  border: "1px solid #B7D4FF",
                  borderBottom: "3px solid #B7D4FF",
                  padding: "15px",
                  // backgroundColor: "#F8F9FC",
                  marginTop: "30px",
                  borderRadius: "7px",
                }}
              >
                <Typography
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  component="p"
                >
                  Subtotal:{" "}
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "18px",
                      fontWeight: 500,
                      // color: "#000",
                    }}
                  >
                    ${subTotal?.toFixed(2)}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  component="p"
                >
                  Tax: {quoteDetail?.quote_tax_rate}%{" "}
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "18px",
                      fontWeight: 500,
                      // color: "#000",
                    }}
                  >
                    ${addTax()?.tax?.toFixed(2)}
                  </Typography>
                </Typography>
                <Divider p={1} />
                <Typography
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  component="p"
                >
                  Grand Total:{" "}
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "18px",
                      fontWeight: 500,
                      // color: "#000",
                    }}
                  >
                    ${addTax()?.total?.toFixed(2)}
                  </Typography>
                </Typography>
              </Box>

              <Box mt={4} mb={2}>
                <Typography variant="subtitle2">
                  Terms & Conditions:{" "}
                </Typography>
                <Box
                  mt={0.5}
                  mb={2}
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <Box sx={{ width: "80%" }}>
                    <CustomTextField
                      form={formik}
                      field={formik.getFieldProps("term_condi_text")}
                    />
                  </Box>
                  <Box sx={{ width: "20%" }}>
                    <CustomFileInput
                      form={formik}
                      type="file"
                      customIcon={
                        <>
                          <Box
                            sx={{
                              px: 2,
                              border: 1,
                              borderColor: ThemeColors.borderColor,
                              borderRadius: 1,
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                              alignItems: "center",
                            }}
                          >
                            <UploadFileOutlined
                              htmlColor={ThemeColors.iconColor}
                            />
                            <Typography noWrap sx={{}}>
                              {Array.isArray(
                                formik.values?.term_condi_file
                              ) && (
                                  <>
                                    {Array.isArray(
                                      formik.values?.term_condi_file
                                    ) &&
                                      formik.values?.term_condi_file?.length &&
                                      formik.values?.term_condi_file?.length > 1
                                      ? `${formik.values?.term_condi_file?.length} files Selected`
                                      : formik?.values?.term_condi_file[0]?.name}
                                  </>
                                )}
                            </Typography>
                          </Box>
                          {!Array.isArray(formik.values?.term_condi_file) &&
                            formik.values?.term_condi_file}
                        </>
                      }
                      field={formik.getFieldProps("term_condi_file")}
                    />
                  </Box>
                </Box>
              </Box>

              <Box my={4} pb={10}>
                <Typography sx={{ fontWeight: "500" }}>Notes</Typography>
                <CustomTextField
                  multiline
                  form={formik}
                  // label="Job Description:"
                  field={formik.getFieldProps("notes")}
                />
                <Box
                  my={2}
                  display={"flex"}
                  flexDirection={"column"}
                  rowGap={1}
                  gap={2}
                >
                  <Typography sx={{ mb: -1, fontWeight: "500" }}>
                    Schedule Data & Time
                  </Typography>
                  <Box display={"flex"} rowGap={1} gap={1}>
                    <CustomDatePicker form={formik} name="date" />
                    <CustomTimePicker form={formik} name="time" />
                  </Box>
                </Box>
              </Box>

              <CustomButton
              disabled={formik?.isSubmitting} 
                onClick={() => {
                  setDialogOpen(true);
                }}
                label="Update"
              />
            </>
          ) : (
            ""
          )}
        </Box>
      )}

      <RadioListFullPageWrapper
        label="Choose Email, Phone or both"
        open={dialogOpen}
        handleClose={handleClose}
      >
        {selectValue?.AdditionEmails?.length ? (
          selectValue?.AdditionEmails.map((item) => {
            return (
              <Typography
                sx={{
                  mb: 1,
                  borderRadius: 1,
                  border: 0.1,
                  borderColor: ThemeColors.borderColor,
                  ":hover": {
                    boxShadow: ThemeColors.hoverBoxShadow,
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  handleEmailSelection(item.email);
                }}
              >
                <ListItem
                  components={"div"}
                  // onClick={onClick}
                  secondaryAction={
                    <IconButton edge="end" aria-label="detail-page">
                      {formik.values?.schedule_email?.includes(item?.email) ? (
                        <>
                          <CheckCircleIcon htmlColor="#1976D2" />
                        </>
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </IconButton>
                  }
                >
                  <ListItemText primary={item.email} />
                </ListItem>

                <Divider
                  sx={{
                    borderRadius: 4,
                  }}
                />
              </Typography>
            );
          })
        ) : (
          <NoResultFound />
        )}

        <CustomButton  disabled={formik?.isSubmitting} onClick={formik.handleSubmit} label={"Send Quote"} />
      </RadioListFullPageWrapper>
    </>
  );
}


