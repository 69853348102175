import React, { useEffect, useState } from "react";
import {
  CustomDatePicker,
  CustomTimePicker,
  NavBar,
  TeamSelectRadioCard,
} from "../../../components";
import * as Yup from "yup";

import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { ClientCard } from "../../../components/Cards";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import { useFormik } from "formik";
import {
  arrayValidationSchema,
  dateValidationSchema,
  numericValidationSchema,
  stringValidationSchema,
  timeValidationSchema,
} from "../../../constant/validationSchema";
import MergedDateAndTime from "../../../utils/MergedDateAndTime";
import dayjs from "dayjs";
import { jobStatus } from "../../../constant/GlobalVariable";
export function JobUpdate() {
  const { job_id } = useParams();
  const navigate = useNavigate();

  const [jobDetail, setJobDetail] = useState("");
  const fetchJobDetail = () => {
    ApiClient.job.get("?job_id=" + job_id).then((res) => {
      if (res) {
        setJobDetail(res.data[0]);
        if (res?.data[0]?.teams_id) {
          formik.setFieldValue("team", res?.data[0]?.team_details);
        }
        if (res.data[0]?.schedule_date) {
          formik.setFieldValue("date", res?.data[0]?.schedule_date);
          formik.setFieldValue("time", res?.data[0]?.schedule_date);
        }
      } else {
        CustomToaster.error(res.message);
      }
    });
  };

  useEffect(() => {
    fetchJobDetail();
  }, []);

  const formik = useFormik({
    initialValues: {
      date: "",
      time: "",
      team: [],
      job_status: jobStatus.DRAFT,
    },
    onSubmit: (values, { resetForm }) => {
      updateJob(values, resetForm);
    },
  });

  const updateJob = (data, resetForm) => {
    const updateDate = data?.date && data?.time && MergedDateAndTime(data.date, data.time);
    
    let jobdata = {
      teams_id: data?.team?.map((item) => item?.id).toString() || "",
      schedule_date: updateDate || null,
      job_status: data.date && data.time && data.team?.length ? jobStatus.UPCOMING : jobStatus.DRAFT,
    };

    ApiClient.job.put(job_id, jobdata).then((res) => {
      if (res) {
        CustomToaster.success(res.message);
        navigate("/jobs");
        resetForm();
      }
    });
  };

  return (
    <>
      <NavBar goBack noFilter noSearch title="Jobs" />
      {!jobDetail ? (
        <LoadingEffect />
      ) : (
        <>
          <Box
            m={1}
            display={"flex"}
            flexDirection={"column"}
            rowGap={1}
            gap={2}
          >
            <ClientCard data={jobDetail?.client_details} />
            <Typography sx={{ mb: -1 }}>Date & Time</Typography>
            <Box display={"flex"} rowGap={1} gap={1}>
              <CustomDatePicker form={formik} name="date" />
              <CustomTimePicker form={formik} name="time" />
            </Box>
            <Typography sx={{ mb: -1 }}>Team</Typography>
            <TeamSelectRadioCard
              form={formik}
              name={"team"}
              alreadyTeam={formik.values.team}
            />

            <Box
              sx={{
                position: "fixed",
                zIndex: 100,
                bottom: 20,
                left: 0,
                right: 0,
                maxWidth: "400px",
                width: "90%",
                display: "flex",
                py: 1,
                margin: "auto",
                fontSize: 18,
              }}
              gap={2}
            >
              <CustomButton disabled={formik?.isSubmitting}  label="Submit" onClick={formik.handleSubmit} />
              {/* <Button
                variant="outlined"
                sx={{ py: 1, fontWeight: "500" }}
                fullWidth
                onClick={formik.handleSubmit}
              >
                Save
              </Button>
              <Button
                disabled={
                  !formik.values.date ||
                  !formik.values.time ||
                  !formik.values.team?.length
                }
                variant="contained"
                sx={{ py: 1, fontWeight: "500" }}
                fullWidth
                onClick={() => {
                  formik.setFieldValue("job_status", 3);
                  formik.handleSubmit();
                }}
              >
                Create Job
              </Button> */}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
