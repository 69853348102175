import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import {
  emailValidationSchema,
  phoneValidationSchema,
} from "../../constant/validationSchema";
import { CustomTextField } from "../InputFields/TextFields";
import CustomButton from "../ActionButtons/CustomButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";
import { DeleteConfirmationDialog } from "../DeleteDetails/DeleteConfirmationDialog";
import { ThemeColors } from "../../constant/ThemeColors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

const HandleUpdatePhone = ({ phone, onClose }) => {
  const [isDeleted, setIsDeleted] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      phone: phone?.phone,
    },
    validationSchema: Yup.object().shape({
      phone: phoneValidationSchema,
    }),
    onSubmit: (values) => {
      handleChange(values);
    },
  });

  const handleDelete = () => {
    ApiClient.phone.delete(phone.id).then((res) => {
      if (res) {
        setIsDeleted(true);
        onClose();
        CustomToaster.success(res.message);
      }
    });
  };

  const handleChange = (data) => {
    ApiClient.phone.put(phone.id, data).then((res) => {
      if (res) {
        onClose();
        CustomToaster.success(res.message);
      }
    });
  };

  if (isDeleted) {
    return <></>;
  }

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
        width: "96%",
        marginX: 'auto'
      }}
      onSubmit={formik.handleSubmit}
    >
      <Grid container paddingX={1} spacing={1}>
        <Grid xs={12} mt={1} item>
          <CustomTextField
            sx={{
              borderColor: "red",
            }}
            form={formik}
            field={formik.getFieldProps("phone")}
            margin="normal"
            label="Phone"
          />
        </Grid>
        {phone?.is_primary ? (
          <Grid
            xs={12}
            item
            // display={"flex"}
            // justifyContent={'center'}
            // alignItems={"flex-end"}
            mt={2}
          >
            <CustomButton
            disabled={formik?.isSubmitting} 
              label="Save"
              onClick={formik.handleSubmit}
              sx={{
                position: {
                  md: 'relative',
                  xs: 'absolute'
                }
              }}
            /> </Grid>
        ) : (
          <Grid
            xs={12}
            item
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"space-between"}
            gap={2}
            mt={2}
            sx={{
              position: {
                md: 'relative',
                xs: 'absolute'
              },
              bottom: {
                md: 'auto',
                xs: 24
              },
              width: '96%',
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              color="error"
              sx={{ mt: -0.5, }}
            >
              <DeleteConfirmationDialog
                iconText="Delete"
                onSubmit={handleDelete}
              />
            </Button>
            <Button
              sx={{ py: 1.2 }}
              fullWidth
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export function UpdatePhone({ phones, onUpdate }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onUpdate();
  };

  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
    onUpdate();
  };

  const [renderInput, setRenderInput] = React.useState("");

  const renderInputField = () => {
    if (phones?.length === 1) {
      return phones?.map((phone, index) => (
        <Box sx={{ my: 2 }}>
          <HandleUpdatePhone
            phone={phone}
            onUpdate={onUpdate}
            onClose={handleClose}
          />
        </Box>))
    }

    return phones?.map((phone, index) => (
      // HandleUpdatePhone({ phone })
      <Typography
        fullWidth
        onClick={() => {
          setRenderInput(
            <HandleUpdatePhone
              phone={phone}
              onUpdate={onUpdate}
              onClose={handleClose}
            />
          );
        }}
        // component={"button"}
        sx={{
          mx: 2,
          my: 0.5,
          mt: index === 0 ? 2 : 0.8,
          border: 0,
          padding: 0.8,
          py: 1.6,
          borderRadius: 2,
          color: "black",
          border: 0.2,
          borderColor: ThemeColors.borderColor,
          ":hover": {
            cursor: "pointer",
            boxShadow: ThemeColors.hoverBoxShadow,
          },
        }}
      >
        {phone.phone}
      </Typography>
    ));
  };

  return (
    <>
      <IconButton
        onClick={() => {
          if (window.innerWidth < 900) {
            handleClickOpen();
          } else {
            handleClickOpenMd();
          }
        }}
        size="small"
      >
        <EditIcon fontSize="12px" />
      </IconButton>

      <Dialog fullWidth open={openMd} onClose={handleCloseMd}>
        <DialogTitle> Update Phone</DialogTitle>
        <>
          <Box fullWidth sx={{ mb: 2 }} >

            {renderInput ? (
              <>
                <Typography
                  size="small"
                  onClick={() => setRenderInput("")}
                  variant="button"
                  sx={{
                    mx: 2,
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 8,
                    border: 1,
                    px: 1,
                    ':hover': {
                      cursor: 'pointer'
                    },
                    width: "max-content",
                    fontSize: 12,
                    borderColor: ThemeColors.borderColor,
                  }}
                >
                  <KeyboardDoubleArrowLeftIcon /> Back
                </Typography>
                {renderInput}
              </>
            ) : (
              renderInputField()
            )}

          </Box>

          {/* <Box fullWidth sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ width: "300px" }}
              variant="contained"
              onClick={handleCloseMd}
            >
              Done
            </Button>
          </Box> */}
        </>
      </Dialog>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0.5, flex: 1 }} variant="h6" component="div">
              Update Phone
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>

        </AppBar>
        {/* {renderInputField()} */}
        {renderInput ? (
          <>
            <Typography
              size="small"
              onClick={() => setRenderInput("")}
              variant="button"
              sx={{
                mx: 2,
                mt: 2,
                display: "flex",
                alignItems: "center",
                borderRadius: 8,
                border: 1,
                px: 1,
                ':hover': {
                  cursor: 'pointer'
                },
                width: "max-content",
                fontSize: 12,
                borderColor: ThemeColors.borderColor,
              }}
            >
              <KeyboardDoubleArrowLeftIcon /> Back
            </Typography>
            {renderInput}
          </>
        ) : (
          renderInputField()
        )}

        {/* <CustomButton onClick={handleClose} label="Done" /> */}
      </Dialog>
    </>
  );
}
