import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        onClick={() => setOpen(!open)}
        sx={{ "& > *": { borderBottom: "unset" }, background: "#E1EDFF", marginTop:'20px !important' }}
      >
        <TableCell align="left" colSpan={4}>
          <IconButton size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>

          {row.date}
        </TableCell>
        <TableCell align="left">{row.total}</TableCell>
      </TableRow>

      <TableRow
        sx={{
          //   "& > *": { borderBottom: "unset" },
          display: open ? "table-row" : "none",
        }}
      >
        <TableCell align="left">{row.date}</TableCell>
        <TableCell align="left">{row.job}</TableCell>
        <TableCell align="left">{row.startTime}</TableCell>
        <TableCell align="left">{row.endTime}</TableCell>
        <TableCell align="left">{row.total}</TableCell>
      </TableRow>
    </>
  );
}

const rows = [
  {
    date: "Nov 2, 2020",
    job: "Clean the kitchen and the stove (SWIPE)",
    startTime: "02:40 pm",
    endTime: "03:54 pm",
    total: "74",
  },
  {
    date: "Nov 2, 2020",
    job: "Clean the kitchen and the stove (SWIPE)",
    startTime: "02:40 pm",
    endTime: "03:54 pm",
    total: "74",
  },
  {
    date: "Nov 2, 2020",
    job: "Clean the kitchen and the stove (SWIPE)",
    startTime: "02:40 pm",
    endTime: "03:54 pm",
    total: "74",
  },
  {
    date: "Nov 2, 2020",
    job: "Clean the kitchen and the stove (SWIPE)",
    startTime: "02:40 pm",
    endTime: "03:54 pm",
    total: "74",
  },
  {
    date: "Nov 2, 2020",
    job: "Clean the kitchen and the stove (SWIPE)",
    startTime: "02:40 pm",
    endTime: "03:54 pm",
    total: "74",
  },
  {
    date: "Nov 2, 2020",
    job: "Clean the kitchen and the stove (SWIPE)",
    startTime: "02:40 pm",
    endTime: "03:54 pm",
    total: "74",
  },
  {
    date: "Nov 2, 2020",
    job: "Clean the kitchen and the stove (SWIPE)",
    startTime: "02:40 pm",
    endTime: "03:54 pm",
    total: "74",
  },
  {
    date: "Nov 2, 2020",
    job: "Clean the kitchen and the stove (SWIPE)",
    startTime: "02:40 pm",
    endTime: "03:54 pm",
    total: "74",
  },
];

export default function CollapsibleTable() {
  return (
    <TableContainer component="div">
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Job</TableCell>
            <TableCell align="left">Start Time</TableCell>
            <TableCell align="left">End Time</TableCell>
            <TableCell align="left">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.job} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
