import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../redux/actions";
import { NavBar, NoResultFound } from "../../../components";
import { Box } from "@mui/material";
import { TextArrowCard } from "../../../components/Cards/DisplayCards";
import ApiClient from "../../../constant/API_URLS";
import { useParams } from "react-router-dom";
import { AddCustomText } from "../../../components/AddAdditionalContact";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import CustomToaster from "../../../utils/CustomToaster";
import { UpdateText } from "../../../components/UpdateDetails/UpdateText";
import { DeleteConfirmationDialog } from "../../../components/DeleteDetails";

export const SubCategory = () => {
  const dispatch = useDispatch();
  const { catId } = useParams();
  const [catergory, setCategory] = useState("");

  const fetchDetail = () => {
    ApiClient.serviceSubCategory
      .get(catId)
      .then((res) => {
        setCategory(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const updateCategory = (data, handleClose, rest) => {
    ApiClient.serviceSubCategory.put(rest?.id, data).then((res) => {
      if (res) {
        CustomToaster.success(res?.message);
        handleClose();
        fetchDetail();
      }
    });
  };

  const deleteCategory = (id, handleClose, rest) => {
    ApiClient.serviceSubCategory.delete(id).then((res) => {
      if (res) {
        CustomToaster.success(res?.message);
        handleClose();
        fetchDetail();
      }
    });
  };

  useEffect(() => {
    if (catId) {
      fetchDetail();
    }
  }, []);

  return (
    <>
      <NavBar title="Categories" goBack  noFilter noSearch />
      <Box mt={1}>
        {!catergory ? (
          <LoadingEffect />
        ) : (
          <>
            {catergory?.length ? (
              <>
                {catergory?.map((cat) => (
                  <TextArrowCard key={cat.id} title={cat.name} url={cat.id}
                    onUpdate={
                      <UpdateText
                        id={cat.id}
                        name={"name"}
                        value={cat.name}
                        iconText="Edit "
                        onSubmit={updateCategory}
                        label="Category"
                      />
                    }
                    onDelete={
                      <DeleteConfirmationDialog
                        id={cat.id}
                        name={"name"}
                        value={cat.name}
                        iconText="Delete"
                        onSubmit={deleteCategory}
                        label="Category"
                      />
                    }


                  />
                ))}
              </>
            ) : (
              <>
                <NoResultFound>No Result Found</NoResultFound>
              </>
            )}
          </>
        )}
      </Box>

      <AddCustomText
        initialValues={{ servicecategoryid: catId }}
        title={"Add Category"}
        label={" Category"}
        api={ApiClient.serviceSubCategory}
        name={"name"}
        onUpdate={fetchDetail}
      />
    </>
  );
};
