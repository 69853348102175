import React, { useEffect, useState } from "react";
import {
  ClientSelectRadioCard,
  CustomDatePicker,
  CustomTimePicker,
  JobDetailContainerListCard,
  NavBar,
  SelectAddressRadioCard,
  TeamSelectRadioCard,
} from "../../../components";
import { Box, Divider, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  arrayValidationSchema,
  numericValidationSchema,
  stringValidationSchema,
} from "../../../constant/validationSchema";
import { CustomTextField } from "../../../components/InputFields/TextFields";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import MergedDateAndTime from "../../../utils/MergedDateAndTime";
import { jobStatus } from "../../../constant/GlobalVariable";
import CalculateTotalServiceMaterial from "../../../utils/CalculateTotalServiceMaterial";
import { transformMaterialArray, transformServiceArray } from "../../../utils/transformMaterialService";

export function UpdateJobFull() {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [material, setMaterial] = useState([]);



  function calculateTotal(jobData) {
    let total = 0;

    if (jobData.materials && Array.isArray(jobData.materials)) {
      jobData.materials.forEach((material) => {
        if (material.total && material.unit_price && material.unit_count) {
          // total += parseFloat(material.total);
          const unitPrice = parseFloat(material.unit_price);
          const unitCount = parseFloat(material.unit_count);
          total += unitPrice * unitCount;
        }
      });
    }
    if (jobData.services && Array.isArray(jobData.services)) {
      jobData.services.forEach((service) => {
        if (service.total && service.unit_price && service.unit_count) {
          // total += parseFloat(service.total);
          const unitPrice = parseFloat(service.unit_price);
          const unitCount = parseFloat(service.unit_count);
          total += unitPrice * unitCount;
        }
      });
    }

    let rate = jobData?.tax_percent_rate || 0;
    let tax = ((Number(total) * Number(rate)) / 100);
    return { total: Number(total) + ((Number(total) * Number(rate)) / 100) + tax, tax, subTotal: total }


  }



  const [selectValue, setSelectValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");

  const addTax = () => {
    let rate = jobDetail?.tax_percent_rate || 0;
    let tax = (Number(subTotal) * Number(rate)) / 100;
    return {
      total: Number(subTotal) + (Number(subTotal) * Number(rate)) / 100,
      tax,
    };
  };


  const formik = useFormik({
    initialValues: {
      materials: [],
      services: [],
      address_id: "",
      client_id: "",
      name: "",
      description: "",
      team: [],
    },
    validationSchema: Yup.object().shape({
      address_id: numericValidationSchema,
      client_id: numericValidationSchema,
      name: stringValidationSchema,
      description: stringValidationSchema,
      services:arrayValidationSchema
    }),
    onSubmit: (values, { resetForm }) => {
      createJob(values, resetForm);
    },
  });

  const createJob = (data, resetForm) => {
    const updateDate = data?.date && data?.time && MergedDateAndTime(data.date, data.time);
    let jobdata = {
      ...data,
      teams_id: data.team?.map((item) => item.id).toString(),
      schedule_date: updateDate || null,
      job_status: data.date && data.time && data.team?.length ? jobStatus.UPCOMING : jobStatus.DRAFT,
    };
    delete jobdata["time"];
    delete jobdata["date"];
    delete jobdata["team"];

    ApiClient.job.put(job_id, jobdata).then((res) => {
      if (res) {
        CustomToaster.success(res.message);
        resetForm();
        setMaterial([]);
        setSelectValue("");
        setServices([]);
        navigate(-1);
      }
    });
  };

  const [subTotal, setSubTotal] = useState(0);
  const calculateSubTotal = () => {
    let subTotal = 0;
    for (const mat of formik.values?.materials) {
      const unitCount = parseInt(mat?.unit_count || 1);
      const purchasePrice = parseInt(mat?.unit_price);
      subTotal += unitCount * purchasePrice;
    }
    for (const service of formik.values?.services) {
      const unitCount = parseInt(service?.unit_count || 1);
      const purchasePrice = parseInt(service?.unit_price);
      subTotal += unitCount * purchasePrice;
    }
    setSubTotal(subTotal);
  };

  useEffect(() => {
    formik.setFieldValue("services", transformServiceArray(services));
    formik.setFieldValue("materials", transformMaterialArray(material));
    formik.values.address_id = selectedAddress?.id;
    formik.values.client_id = selectValue?.id;

    setSubTotal(CalculateTotalServiceMaterial(transformMaterialArray(material), transformServiceArray(services)));


  }, [material, services, selectValue, selectedAddress]);

  const { job_id } = useParams();

  const [jobDetail, setJobDetail] = useState("");
  const fetchJobDetail = () => {
    ApiClient.job.get("?job_id=" + job_id).then((res) => {
      if (res) {
        setJobDetail(res.data[0]);
      } else {
        CustomToaster.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (jobDetail) {
      formik.setFieldValue("address_id", Number(jobDetail?.address_id));
      formik.setFieldValue("client_id", jobDetail?.client_id);
      formik.setFieldValue("team", jobDetail?.team_details);
      formik.setFieldValue("date", jobDetail?.schedule_date);
      formik.setFieldValue("time", jobDetail?.schedule_date);
      formik.setFieldValue("name", jobDetail?.name);
      formik.setFieldValue("description", jobDetail?.description);
      setMaterial(jobDetail?.materials);
      setServices(jobDetail?.services);
    }
  }, [jobDetail]);

  useEffect(() => {
    calculateSubTotal();
  }, [formik]);

  useEffect(() => {
    fetchJobDetail();
  }, []);

  return (
    <>
      <NavBar title="Update Job" goBack noFilter noSearch />
      {!jobDetail ? (
        <LoadingEffect />
      ) : (
        <Box sx={{ p: 1 }}>
          <ClientSelectRadioCard
            clientId={jobDetail?.client_id}
            onSubmit={setSelectValue}
          />
          {selectValue?.id ? (
            <>
              <SelectAddressRadioCard
                data={selectValue}
                onSubmit={setSelectedAddress}
              />
              <Divider />

              <Box mt={4} mb={2}>
                <Typography sx={{ fontWeight: "500" }}>Job Name *</Typography>
                <CustomTextField
                  // label="Job Name"
                  form={formik}
                  field={formik.getFieldProps("name")}
                />
              </Box>
              <JobDetailContainerListCard
                editable
                listData={services}
                setListData={setServices}
                cardButton="service"
              />
               <Typography
              variant="subtitle2"
              component={"span"}
              sx={{ color: "red" }}
            >
              {formik?.touched.services && formik?.errors?.services}
            </Typography>
            
              <JobDetailContainerListCard
                cardTitle="Material"
                editable
                listData={material}
                setListData={setMaterial}
                cardButton="material"
              />

           
<Box
              sx={{
                my: 3,
                border: "1px solid #B7D4FF",
                borderBottom: "3px solid #B7D4FF",
                padding: "15px",
                // backgroundColor: "#F8F9FC",
                marginTop: "30px",
                borderRadius: "7px",
              }}
            >
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                component="p"
              >
                Subtotal:{" "}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    // color: "#000",
                  }}
                >
                  ${subTotal?.toFixed(2)}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                component="p"
              >
                Tax: {jobDetail?.tax_percent_rate}%
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    // color: "#000",
                  }}
                >
                  ${addTax()?.tax?.toFixed(2)}
                </Typography>
              </Typography>
              <Divider p={1} />
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                component="p"
              >
                Grand Total:{" "}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    // color: "#000",
                  }}
                >
                  ${addTax()?.total?.toFixed(2)}
                </Typography>
              </Typography>
            </Box>

              <Box my={4} pb={10}>
                <Typography sx={{ fontWeight: "500" }}>
                  Job Description *
                </Typography>
                <CustomTextField
                  multiline
                  form={formik}
                  // label="Job Description:"
                  field={formik.getFieldProps("description")}
                />

                <Box
                  m={1}
                  my={2}
                  display={"flex"}
                  flexDirection={"column"}
                  rowGap={1}
                  gap={2}
                >
                  <Typography sx={{ mb: -1, fontWeight: "500" }}>
                    Data & Time
                  </Typography>
                  <Box display={"flex"} rowGap={1} gap={1}>
                    <CustomDatePicker form={formik} name="date" />
                    <CustomTimePicker form={formik} name="time" />
                  </Box>
                  <Typography sx={{ mb: -1, fontWeight: "500" }}>
                    Team
                  </Typography>
                  <TeamSelectRadioCard
                    form={formik}
                    name={"team"}
                    alreadyTeam={formik.values.team}
                  />
                </Box>
              </Box>

              <CustomButton disabled={formik?.isSubmitting}  onClick={formik.handleSubmit} label="Update" />
            </>
          ) : (
            ""
          )}
        </Box>
      )}
    </>
  );
}


