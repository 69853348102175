import React, { useState, useEffect, useRef } from "react";
const GOOGLE_MAP_API = "AIzaSyBd0PYN77escnzaFd5L_kjgZOMweC8jJo4";

const useGoogleMaps = (callbackName) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API}&libraries=places`;
        script.async = true;
        // script.defer = true;

        script.addEventListener("load", () => {
            setScriptLoaded(true);
        });

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [callbackName]);

    return scriptLoaded;
};

export const usePlacesAutocomplete = () => {
    const [suggestions, setSuggestions] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState(null); // New state to store selected place details

    const isGoogleMapsScriptLoaded = useGoogleMaps("");

    const fetchSuggestions = (input) => {
        if (!input) {
            setSuggestions([]);
            return;
        }
        if (isGoogleMapsScriptLoaded) {
            const autocompleteService =
                new window.google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
                {
                    input,
                    componentRestrictions: { country: "ca" },
                    // types: ["premise"],
                },
                (results, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setSuggestions(results);
                    }
                }
            );
        }
    };

    // New function to fetch place details using the place ID
    const fetchPlaceDetails = (placeId) => {
        if (placeId) {
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLE_MAP_API}`)
                .then((res) => res.json())
                .then((result) => {
                    setSelectedPlace(result?.results[0]);
                });
        }
    };

    return [suggestions, fetchSuggestions, selectedPlace, fetchPlaceDetails];
};

export const useGoogleMapGeocoder = () => {
    const isGoogleMapsScriptLoaded = useGoogleMaps();
    const [geoCodeAddress, setGeoCodeAddress] = useState(null);

    const fetchGeoCodeAddress = (address) => {
        if (isGoogleMapsScriptLoaded) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: address }, (results, status) => {
                if (status === "OK") {
                    setGeoCodeAddress(results[0]);
                } else {
                    console.log(
                        "Geocode was not successful for the following reason: " + status
                    );
                    setGeoCodeAddress(null);
                }
            });
        }
    };

    return [geoCodeAddress, fetchGeoCodeAddress];
};

export const MapModal = ({ onLocationSelect, onLocationChange }) => {
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [position, setPosition] = useState({ lat: 37.7749, lng: -122.4194 }); // default position of the marker
    const mapRef = useRef(null);

    const isGoogleMapsScriptLoaded = useGoogleMaps();

    useEffect(() => {
        if (isGoogleMapsScriptLoaded) {
            const options = {
                center: position,
                zoom: 12,
            };
            const map = new window.google.maps.Map(mapRef.current, options);
            const marker = new window.google.maps.Marker({
                position: position,
                map: map,
                draggable: true,
            });
            setMap(map);
            setMarker(marker);
        }
    }, [isGoogleMapsScriptLoaded]);

    useEffect(() => {
        if (marker) {
            window.google.maps.event.addListener(marker, "dragend", () => {
                const newPosition = {
                    lat: marker.getPosition().lat(),
                    lng: marker.getPosition().lng(),
                };
                setPosition(newPosition);
                map.panTo(newPosition);
                onLocationChange(newPosition);
            });
        }
    }, [marker, map]);

    const handleLocationSelect = () => {
        onLocationSelect(position);
    };

    return (
        <div>
            <div ref={mapRef} style={{ height: "300px" }}></div>
            <button onClick={handleLocationSelect}>Select Location</button>
        </div>
    );
};
