import React, { useEffect, useState } from "react";
import { CustomSwipableTabs, NavBar, NoResultFound } from "../../components";
import { Avatar, Box, Typography } from "@mui/material";
import ApiClient from "../../constant/API_URLS";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";
import { useSelector } from "react-redux";
import { ThemeColors } from "../../constant/ThemeColors";
import GroupByJobTable from "./TimesheetTables/GroupByJobTable";
import GroupByTeamMemberTable from "./TimesheetTables/GroupByTeamMemberTable";
import GroupByDateTable from "./TimesheetTables/GroupByDateTable";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export const TimeSheet = () => {
  // const [timeSheet, setTimeSheet] = useState("");



  // const [filteredData, setFilteredData] = useState("");
  // const searchText = useSelector((state) => state?.searchBarText);
  // useEffect(() => {
  //   if (searchText && timeSheet) {
  //     const searchTextLowerCase = searchText.toLowerCase();
  //     const filteredData = timeSheet.filter((item) => {
  //       const nameLowerCase = item?.job_details[0]?.name.toLowerCase();

  //       const userMatches = item?.user_details.some((user) => {
  //         const userFirstName = user.first_name.toLowerCase();
  //         const userLastName = user.last_name.toLowerCase();
  //         return (
  //           searchTextLowerCase.includes(userFirstName) ||
  //           searchTextLowerCase.includes(userLastName) ||
  //           userFirstName.includes(searchTextLowerCase) ||
  //           userLastName.includes(searchTextLowerCase)
  //         );
  //       });

  //       // Check if search text matches any team info
  //       const teamMatches = item?.team_info.some((team) => {
  //         const teamFirstName = team.first_name.toLowerCase();
  //         const teamLastName = team.last_name.toLowerCase();
  //         return (
  //           searchTextLowerCase.includes(teamFirstName) ||
  //           searchTextLowerCase.includes(teamLastName) ||
  //           teamFirstName.includes(searchTextLowerCase) ||
  //           teamLastName.includes(searchTextLowerCase)
  //         );
  //       });

  //       return (
  //         nameLowerCase.includes(searchTextLowerCase) ||
  //         searchTextLowerCase.includes(nameLowerCase) ||
  //         userMatches ||
  //         teamMatches
  //       );
  //     });
  //     setFilteredData(filteredData);
  //   } else {
  //     setFilteredData("");
  //   }
  // }, [searchText]);

  const [option, setOption] = React.useState('job');

  function SelectSmall() {

    const handleChange = (event) => {
      setOption(event.target.value);
    };

    return (
      <FormControl sx={{ mx: 1, minWidth:160 }} size="small">

        <Select
          sx={{
            // color: 'white',
            background: '#E1EDFF',
            px: 1,
            py: 0.5,
            borderRadius: 2,
            width: 'auto',
            '&.MuiInputBase-root': {
              borderBottom: 'none',
              '&:before, &:after': {
                borderBottom: 'none',
              },
              '&:hover': {
                borderBottom: 'none',
              },
              '&.Mui-focused': {
                borderBottom: 'none',
              },
              '&:hover:not(.Mui-disabled, .Mui-error):before': {
                borderBottom: 'none',
              },
            },
          }}
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={option}
          onChange={handleChange}
          variant='standard'

        >
          <MenuItem value={'job'}>Job</MenuItem>
          <MenuItem value={'team'}>Team Member</MenuItem>
          <MenuItem value={'date'}>Date</MenuItem>
        </Select>


      </FormControl>
    );
  }



  const tabData = [

    { label: "Job", value: <GroupByJobTable /> },
    { label: "Team Member", value: <GroupByTeamMemberTable /> },
    { label: "Date", value: <GroupByDateTable /> },
  ]

  return (
    <>
      <NavBar title="TimeSheet" noFilter noSearch />
      <SelectSmall />
      {/* {timeSheet ? (
        <>
          {timeSheet?.length ? (
            <>
              <Typography>
                Result Showing :{" "}
                <Typography component={"strong"} style={{ fontWeight: "700" }}>
                  {filteredData ? filteredData.length : timeSheet.length}
                </Typography>
              </Typography>
              <Box
                sx={{ display: "flex", flexWrap: "wrap", gap: 1, marginTop: 1 }}
              >
                {filteredData
                  ? filteredData.map((item, index) => (
                      <TimeSheetCard key={item.id} data={item} />
                    ))
                  : timeSheet.map((item, index) => (
                      <TimeSheetCard key={item.id} data={item} />
                    ))}
              </Box>
            </>
          ) : (
            <NoResultFound />
          )}
        </>
      ) : (
        <LoadingEffect />
      )} */}

      {option === 'job' ? <GroupByJobTable /> : ''}
      {option === 'team' ? <GroupByTeamMemberTable /> : ''}
      {option === 'date' ? <GroupByDateTable /> : ''}


    </>
  );
};


